import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { API } from './api-service'; // Adjust the path as necessary
import { useCookies } from 'react-cookie';

interface Notice {
    id: number;
    title: string;
    content: string;
    created_by: string;
    created_at: string;
  }

  
interface AddNoticeModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  initialNotice: any; 
  mode: 'add' | 'edit';
}

const AddNoticeModal: React.FC<AddNoticeModalProps> = ({ open, onClose, onSuccess, initialNotice, mode }) => {
  const [title, setTitle] = useState(initialNotice?.title || '');
  const [content, setContent] = useState(initialNotice?.content || '');
  const [Token, setToken] = useCookies(['token']);

  useEffect(() => {
    if (initialNotice) {
      setTitle(initialNotice.title);
      setContent(initialNotice.content);
    }
  }, [initialNotice]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const noticeData = {
      title,
      content,
      token: Token['token'],
    };

    try {
      if (mode === 'add') {
        await API.NoticeboardAction('create', noticeData);
      } else if (mode === 'edit' && initialNotice) {
        await API.NoticeboardAction('update', { ...noticeData, id: initialNotice.id });
      }
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Failed to save notice:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h6" gutterBottom>
          {mode === 'add' ? 'Add New Notice' : 'Edit Notice'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Title"
            fullWidth
            margin="normal"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Content"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <Box mt={2}>
            <Button variant="contained" color="primary" type="submit">
              {mode === 'add' ? 'Add Notice' : 'Save Changes'}
            </Button>
            <Button onClick={onClose} sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default AddNoticeModal;
