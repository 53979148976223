import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { API } from './api-service';

interface UploadDialogProps {
  open: boolean;
  onClose: () => void;
  featureType: string;
  token: string;
}

const UploadDialog: React.FC<UploadDialogProps> = ({ open, onClose, featureType, token }) => {
  const [file, setFile] = useState<File | null>(null);
  const [link, setLink] = useState('')

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  
  const formatString = (str: string): string => {
    return str
      .split('_') 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      console.log(token,"   ", featureType,"  ",formData)
      try {
        await API.uploadFile(featureType, token, formData);
        alert('File uploaded successfully');
        onClose();
      } catch (error) {
        console.error('Failed to upload file:', error);
        alert('Failed to upload file');
      }
    }
  };

  // const handleDownload = async (event: React.MouseEvent<HTMLAnchorElement>) => {
  //   console.log(event)
  //   event.preventDefault();
  //   try {
  //     const response:any = await API.downloadFile(featureType, token);
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = `${featureType}_format.xlsx`;
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error('Failed to download file:', error);
  //   }
  // };

  const handleOnClickDownload = async() =>{
    var temp_link = "/media/" + featureType +"_format.xlsx"
    setLink(temp_link)
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload File for {formatString(featureType)}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Download the format file <a href={link} onClick={handleOnClickDownload} download>here</a>
        </Typography>
        <TextField
          type="file"
          onChange={handleFileChange}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleUpload} color="primary" variant="contained">Upload</Button>
      </DialogActions>
    </Dialog>
  );
};


export default UploadDialog;
