import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Typography } from '@mui/material';
import { API } from './api-service';

interface EdituserDialogProps {
  open: boolean;
  onClose: () => void;
  token: string;
  user_id: string;
}

interface Amenity {
  flat: number;
  amenity: number;
  has_access: boolean;
  edited_by: string;
}

const EdituserDialog: React.FC<EdituserDialogProps> = ({ open, onClose, token, user_id }) => {
  const [userAmenities, setUserAmenities] = useState<Amenity[]>([]);
  const [allAmenities, setAllAmenities] = useState<any[]>([]);
  const [amenitiesAccess, setAmenitiesAccess] = useState<{ amenity: string, has_access: boolean }[]>([]);
  const [username, setUsername] = useState<string>();
  const [flatId, setFlatId] = useState<number | null>(null);

  useEffect(() => {
    if (open) {
      fetchUserAmenities();
      fetchAllAmenities();
    }
  }, [open]);

  const fetchUserAmenities = async () => {
    try {
      const response = await API.fetchAmenityDataUser(token, user_id);
      setUsername(response.data.username);
      setFlatId(response.data.flat_id);
      setUserAmenities(response.data.amenities);
      setAmenitiesAccess(response.data.amenities.map((am: Amenity) => ({ amenity: am.amenity.toString(), has_access: am.has_access })));
    } catch (error) {
      console.error('Error fetching user amenities:', error);
    }
  };

  const fetchAllAmenities = async () => {
    try {
      const response = await API.fetchAmenityList(token);
      setAllAmenities(response);
    } catch (error) {
      console.error('Error fetching all amenities:', error);
    }
  };

  const handleAmenityChange = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
    
    const updatedAmenitiesAccess = amenitiesAccess.map(amenity => {
      if (amenity.amenity === id) {
        return { ...amenity, has_access: event.target.checked };
      }
      return amenity;
    });

    if (!updatedAmenitiesAccess.some(amenity => amenity.amenity === id)) {
      updatedAmenitiesAccess.push({ amenity: id, has_access: event.target.checked });
    }
    console.log(updatedAmenitiesAccess)
    setAmenitiesAccess(updatedAmenitiesAccess);
  };

  const handleSubmit = async () => {
    try {
      const request = {
        amenities_access: amenitiesAccess
      };

      if (flatId !== null) {
        await API.updateAmenityAccess(request, flatId, token);
      }

      onClose();
    } catch (error) {
      console.error('Error updating user amenities:', error);
    }
  };

  console.log("all",allAmenities)
  console.log("user",amenitiesAccess)

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit User Amenities</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Username: {username ? username : 'Loading...'}</Typography>
        {allAmenities.map(amenity => (
          <FormControlLabel
            key={amenity.id}
            control={
              <Checkbox
                checked={amenitiesAccess.some(access => access.amenity.toString() === amenity.id.toString() && access.has_access==true)}
                onChange={(event) => handleAmenityChange(amenity.id.toString(), event)}
              />
            }
            label={amenity.name}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EdituserDialog;
