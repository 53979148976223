import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import DataTable from './DataTable_Server';
import { API } from './api-service';
import { useCookies } from 'react-cookie';
import GeneralizedFilter from './Filter';
import HelpComplainEditDialog from './EditHelpComplaintDialog';


const HelpComplaint: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [sortField, setSortField] = useState<string>('last_updated_at');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [Token, setToken] = useCookies(['token']);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<Record<string, any>>({});
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [featureType, setFeatureType] = useState<string>('');
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [currentRow, setCurrentRow] = useState<any>(null);

    const currentDate = new Date();
    const [month, setMonth] = useState<number>(currentDate.getMonth() + 1);
    const [year, setYear] = useState<number>(currentDate.getFullYear());
    const [maintenanceDialogOpen,setMaintenanceDialogOpen] = useState(false)

    useEffect(() => {
        const checkAuth = async () => {
            if (!Token['token']) window.location.href = '/';
        };
        checkAuth();
    }, [Token]);

    const fetchData = async (queryParams: any = {}) => {
        if (!Token['token']) return;

        setLoading(true);
        try {
            const result = await API.fetchHelpComplaintsData(Token['token'], {
                search: searchQuery,
                page: page + 1, // Adjusting for 0-based index
                page_size: rowsPerPage,
                sort_by: sortField,
                order: sortOrder,
                ...filters,
                ...queryParams,
            });
            const finalData = result.results.map((item: any) => ({
                ...item,
                Actions: ['delete','edit'],
            }));
            setData(finalData);
            setTotalRows(result.total_results);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData({ month, year });
    }, [Token, page, rowsPerPage, searchQuery, sortField, sortOrder, filters, month, year]);

    useEffect(() => {
        if (data.length > 0) {
            setHeaders([...Object.keys(data[0])]);
        }
    }, [data]);

    const handleQueryType = (type: string, value: any) => {
        const queryParams: any = {};
        switch (type) {
            case 'sort':
                setSortField(value.property);
                setSortOrder(value.order);
                queryParams.sort_by = value.property;
                queryParams.order = value.order;
                break;
            case 'search':
                setSearchQuery(value);
                queryParams.search = value;
                break;
            case 'page':
                setPage(value);
                queryParams.page = value + 1; // Adjusting for 0-based index
                break;
            case 'rowsPerPage':
                setRowsPerPage(value);
                setPage(0);
                queryParams.page = 1; // Adjusting for 0-based index
                queryParams.page_size = value;
                break;
            case 'booleanChange':
                handleBooleanChange(value.id, value.header, value.value);
                return;
            case 'edit':
                handleEdit(value);
                return;
            case 'delete':
                handleDelete(value);
                return;
            case 'month':
                setMonth(Number(value)+1);
                queryParams.month = Number(value)+1;
                queryParams.year = year;
                break;
            case 'year':
                setYear(value);
                queryParams.year = value;
                queryParams.month = month;
                break;
            default:
                break;
        }
        fetchData(queryParams);
    };

    const handleBooleanChange = async (row: any, header: string, value: boolean) => {
        console.log("boolean change");
    };

    const handleEdit = (row: any) => {
        console.log(`Edit clicked for row with id: ${row}`);
        setCurrentRow(row);
        setEditDialogOpen(true);
    };

    const handleDelete = (row: any) => {
        // console.log(`Delete clicked for row with id: ${id}`);
        console.log(row)
        API.updateDeleteHelpComplaintsData(Token['token'],'DELETE',row.type,row.id,row);
        fetchData()
    };

    const handleFilterChange = (selectedFilters: Record<string, any>, filterName: string) => {
        setFilters(prevFilters => ({ ...prevFilters, ...selectedFilters }));
        fetchData({ ...filters, ...selectedFilters });
    };

    const onSaveHelpComplaint = async() =>{
        setEditDialogOpen(false);
        await fetchData();
    }
    if (!Token['token']) {
        window.location.href = '/';
    }

    return (
        <Box padding={5}>
            <h1>Helps & Complaints</h1>
            {/* <Button variant="outlined" sx={{ marginBottom: '10px' }} onClick={handleBillHistory}>Bill History</Button>
            {/* <Button variant="outlined" sx={{ marginBottom: '10px' }} onClick={handleViewMaintenanceFields}>View Maintenance fields</Button> */}

            <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                    <DataTable
                        headers={headers}
                        data={data}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        rowsPerPage={rowsPerPage}
                        totalRows={totalRows}
                        queryType={handleQueryType}
                        featureName="helps-complaint"
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <GeneralizedFilter feature_type="wings_list" filterName="Wing" onFilterChange={handleFilterChange} token={Token['token']} />
                </Grid>
            </Grid>
        
            <HelpComplainEditDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                row={currentRow}
                onSave={() => onSaveHelpComplaint()}
            />
            
        </Box>
    );
};

export default HelpComplaint;
