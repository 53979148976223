import React from 'react';
import { Box, TextField, Button, Typography, Divider } from '@mui/material';

interface DietFormProps {
  diet: any;
  day: string;
  itemsForDay: { [key: string]: string };
  onAddItem: (day: string, item: { [key: string]: string }) => void;
  onDeleteItem: (day: string, itemName: string) => void;
}

const DietForm: React.FC<DietFormProps> = ({ diet, day, itemsForDay, onAddItem, onDeleteItem }) => {
  const [itemName, setItemName] = React.useState<string>('');
  const [itemQuantity, setItemQuantity] = React.useState<string>('');

  const handleAddClick = () => {
    if (itemName && itemQuantity) {
      onAddItem(day, { [itemName]: itemQuantity });
      setItemName('');
      setItemQuantity('');
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Diet Plan for {day}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ mb: 2 }}>
        {Object.entries(itemsForDay).map(([name, quantity]) => (
          <Box key={name} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              {name}: {quantity}
            </Typography>
            <Button 
              variant="outlined" 
              color="error" 
              onClick={() => onDeleteItem(day, name)}
              sx={{ ml: 2 }}
            >
              Delete
            </Button>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Item Name"
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
          fullWidth
          variant="outlined"
          sx={{ mb: 1 }}
        />
        <TextField
          label="Quantity"
          value={itemQuantity}
          onChange={(e) => setItemQuantity(e.target.value)}
          fullWidth
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Button
          onClick={handleAddClick}
          variant="contained"
          color="primary"
        >
          Add Item
        </Button>
      </Box>
    </Box>
  );
};

export default DietForm;
