import React, { useState } from 'react';
import { Modal, Box, Typography, Button, List, ListItem } from '@mui/material';

interface GymSelectionModalProps {
  open: boolean;
  onClose: () => void;
  gyms: string[]; // Array of gym names
  onSelect: (gym: string) => void;
}

const GymSelectionModal: React.FC<GymSelectionModalProps> = ({ open, onClose, gyms, onSelect }) => {
  const handleSelect = (gym: string) => {
    onSelect(gym);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}>
        <Typography variant="h6" gutterBottom>Select Gym</Typography>
        <List>
          {gyms.map((gym:any, index) => (
            <ListItem button key={gym.id} onClick={() => handleSelect(gym)}>
              {gym.name}
            </ListItem>
          ))}
        </List>
        <Button onClick={onClose} variant="outlined" sx={{ mt: 2 }}>Close</Button>
      </Box>
    </Modal>
  );
};

export default GymSelectionModal;
