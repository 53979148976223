import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';


interface Notice {
  id: number;
  title: string;
  content: string;
  created_by: string;
  created_at: string;
  username: string;
}

interface NoticeProps {
  notice: Notice;
}

const Notice: React.FC<NoticeProps> = ({ notice }) => {
  return (
    <Box>
      <Typography variant="h6" color='text.primary'>{notice.title}</Typography>
      <Typography>{notice.content}</Typography>
      <Typography variant="caption" display="block" gutterBottom>
        Posted by: {notice.username}
      </Typography>
      <Typography variant="caption" display="block" gutterBottom>
        {notice.created_at}
      </Typography>
    </Box>
  );
};

export default Notice;
