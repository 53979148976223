import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import DataTable from './DataTable_Server';
import { API } from './api-service';
import { useCookies } from 'react-cookie';
import EditGymMemberDialog from './EditGymMemberDialog';
import EditTrainerDialog from './EditTrainerDialog';

interface Trainer {
  id: number;
  name: string;
  status: boolean;
  lastLoggedIn: string;
  Actions: string[];
}

const TrainerData: React.FC = () => {
  const [data, setData] = useState<Trainer[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortField, setSortField] = useState<string>('id');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [Token, setToken] = useCookies(['token']);
  const [openEditDialogue, setOpenEditDialogue] = useState(false);
  const [userId, setUserId] = useState<string>('');
  const [trainerData, setTrainerData] = useState<any>(null);

  useEffect(() => {
    const checkAuth = async () => {
      if (!Token['token']) window.location.href = '/';
    };
    checkAuth();
  }, [Token]);

  const fetchData = async (queryParams: any = {}) => {
    if (!Token['token']) return;
    console.log(queryParams);
    const input = {
      search: searchQuery,
      sort_by: sortField,
      order: sortOrder,
      page,
      page_size: rowsPerPage,
      ...queryParams,
    };
    console.log(input);
    const result = await API.getTrainerDataPaginated(Token['token'], input);

    if (result) {
      const users = result.results.map((item: any) => ({
        ...item,
        Actions: ['view'],
      }));
      console.log(result.results);
      setData(users);
      setTotalRows(result.total_results);
    }
  };

  useEffect(() => {
    fetchData();
  }, [Token, page, rowsPerPage, searchQuery, sortField, sortOrder]);

  useEffect(() => {
    if (data.length > 0) {
      setHeaders([...Object.keys(data[0])]);
    }
  }, [data]);

  const handleQueryType = (type: string, value: any) => {
    const queryParams: any = {};
    switch (type) {
      case 'sort':
        setSortField(value.property);
        setSortOrder(value.order);
        queryParams.sortField = value.property;
        queryParams.sortOrder = value.order;
        break;
      case 'search':
        setSearchQuery(value);
        queryParams.search = value;
        break;
      case 'page':
        setPage(value);
        queryParams.page = value;
        break;
      case 'rowsPerPage':
        setRowsPerPage(value);
        setPage(0);
        queryParams.page = 0;
        queryParams.pageSize = value;
        break;
      case 'booleanChange':
        handleBooleanChange(value.id, value.header, value.value);
        return;
      case 'edit':
        handleEdit(value);
        return;
      case 'delete':
        handleDelete(value);
        return;
      default:
        break;
    }
    fetchData(queryParams);
  };

  const handleBooleanChange = async (id: number, header: string, value: boolean) => {
    console.log(id, header, value);
    var request_body = {
      token: Token["token"],
      status: value
    };
    try {
      //   await API.updateUserStatus(id, request_body, "PUT");
      await fetchData();
    } catch (error) {
      console.log(error);
    }
    console.log("boolean change");
  };

  const handleEdit = async (id: any) => {
    console.log("id ", id);
    try {
      const response: any = await API.fetchTrainerMemberData(Token['token'], id);
      console.log(response);
      const trainerData = response.results[0];
      setTrainerData(trainerData);
      setUserId(id);
      setOpenEditDialogue(true);
    } catch (error) {
      console.error('Error fetching trainer data:', error);
    }
  };

  const handleDelete = (id: number) => {
    console.log(`Delete clicked for row with id: ${id}`);
  };

  if (!Token['token']) {
    return <></>;
  }

  return (
    <Box padding={5}>
      <h1>Gym Trainers</h1>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <DataTable
            headers={headers}
            data={data}
            onEdit={handleEdit}
            onDelete={handleDelete}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            queryType={handleQueryType}
            featureName="manage trainer"
          />
        </Grid>
      </Grid>

      <EditTrainerDialog
        open={openEditDialogue}
        onClose={() => setOpenEditDialogue(false)}
        trainerData={trainerData}
      />
    </Box>
  );
};

export default TrainerData;
