import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Button } from '@mui/material';
import { useCookies } from 'react-cookie';
import { API } from './api-service';

interface NavigationListProps {
  isMobile: boolean;
}

const NavigationList: React.FC<NavigationListProps> = ({ isMobile }) => {
  const [Token, setToken, deleteToken] = useCookies(['token']);
  const [Role, setRole] = useCookies(['role']);
  const [navigationViews, setNavigationViews] = useState<any[]>([]);
  const logo = '/media/logo.png';

  useEffect(() => {
    const fetchViews = async () => {
      if (Token['token']) {
        try {
          const views = await API.GetViews(Role['role'], Token['token']);
          if (views.length > 0) {
            setNavigationViews(views[0].navigation_views);
          }
        } catch (error) {
          console.error('Failed to fetch views', error);
        }
      }
      else {
        setNavigationViews([
          {
            name: "view_home",
            endpoint: "/home"
          },
          {
            name: "view_contact",
            endpoint: "/contact"
          }
        ]);
      }
    };

    fetchViews();
  }, [Role, Token]);

  useEffect(() => {
    console.log(navigationViews);
  }, [navigationViews]);

  const handleNavigation = (endpoint: string) => {
    window.location.href = endpoint;
  };

  return isMobile ? (
    <List>
      <ListItem button key="logo" onClick={() => handleNavigation('/')}>
        <img src={logo} alt="Logo" style={{ height: 70, marginRight: 'auto' }} />
      </ListItem>
      {navigationViews.map((view: any) => (
        <ListItem button key={view.name} onClick={() => handleNavigation(view.endpoint)}>
          <ListItemText primary={view.name.replace('view_', '').replace('_', ' ')} />
        </ListItem>
      ))}
    </List>
  ) : (
    <>
      {navigationViews.map((view: any) => (
        <Button
          key={view.name}
          sx={{ mr: 2, color: 'text.primary', alignSelf: 'center' }}
          onClick={() => handleNavigation(view.endpoint)}
        >
          {view.name.replace('view_', '').replace('_', ' ')}
        </Button>
      ))}
    </>
  );
};

export default NavigationList;
