import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Paper, Button, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Notice from './Notice';
import { API } from './api-service';
import AddNoticeModal from './AddNoticeModal';
import '../css/NoticeBoard.css';
import { useCookies } from 'react-cookie';

interface Notices {
  id: number;
  title: string;
  content: string;
  created_by: string;
  created_at: string;
  username : string;
}

const NoticeBoard: React.FC = () => {
  const [notices, setNotices] = useState<Notices[]>([]);
  const [page, setPage] = useState(0);
  const [Token, setToken] = useCookies(['token']);
  const [isModalOpen, setModalOpen] = useState(false);
  const [editNotice, setEditNotice] = useState<Notices | null>(null);
  const [mode, setMode] = useState<'add' | 'edit'>('add');

  const noticesPerPage = 4;

  useEffect(() => {
    const checkAuth = async () => {
      if (!Token['token']) window.location.href = '/';
    };
    checkAuth();
  }, [Token]);

  const fetchNotices = async () => {
    if (Token['token']) {
      try {
        const response = await API.NoticeboardAction('fetch', { token: Token['token'] });
        if (response) {
          setNotices(response);
        } else {
          setNotices([]);
          console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        setNotices([]);
        console.error("Failed to fetch notices:", error);
      }
    }
  };

  useEffect(() => {
    fetchNotices();
  }, [Token]);

  const handlePrevious = () => {
    setPage(prevPage => Math.max(prevPage - 1, 0));
  };

  const handleNext = () => {
    setPage(prevPage => Math.min(prevPage + 1, Math.floor(notices.length / noticesPerPage)));
  };

  const startIndex = page * noticesPerPage;
  const displayedNotices = notices.slice(startIndex, startIndex + noticesPerPage);

  const openModal = () => {
    setEditNotice(null);
    setMode('add');
    setModalOpen(true);
  };

  const openEditModal = (notice: Notices) => {
    setEditNotice(notice);
    setMode('edit');
    setModalOpen(true);
  };
  const deleteNotice = async (notice: Notice) => {
    const noticeData = {
      id: notice.id,
      token: Token['token'],
    };
    try {
      await API.NoticeboardAction('delete', noticeData);
      fetchNotices();
    } catch (error) {
      console.error("Failed to delete notice:", error);
    }
  };

  const closeModal = () => setModalOpen(false);
  const handleSuccess = () => fetchNotices();

  return (
    <Box>
      <Typography variant="h5" component="h2" gutterBottom color='primary.main'>
        Notice Board
        <IconButton aria-label="add" color="primary" onClick={openModal}>
          <AddIcon />
        </IconButton>
      </Typography>
      <Divider />
      <Box sx={{ maxHeight: '500px', overflowY: 'auto', mt: 2 }}>
        <Grid container spacing={2} className="notice-container">
          {displayedNotices.map(notice => (
            <Grid item xs={12} md={6} key={notice.id}>
              <Paper elevation={3} sx={{ marginBottom: 2, padding: 2, border: '1px solid #ccc', ":hover": { boxShadow: 10 } }}>
                <Notice notice={notice} />
                <Box display="flex" justifyContent="space-between">
                  <IconButton aria-label="delete" color="secondary" onClick={()=>{deleteNotice(notice)}}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton aria-label="edit" color="primary" onClick={()=>openEditModal(notice)}>
                    <EditIcon />
                  </IconButton>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={handlePrevious}
          sx={{ marginRight: 1 }}
          disabled={page === 0}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={handleNext}
          disabled={startIndex + noticesPerPage >= notices.length}
        >
          Next
        </Button>
      </Box>
      <AddNoticeModal
        open={isModalOpen}
        onClose={closeModal}
        onSuccess={handleSuccess}
        initialNotice={editNotice}
        mode={mode}
      />
    </Box>
  );
};

export default NoticeBoard;
