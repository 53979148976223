import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Box, Button } from '@mui/material';
import PendingIcon from '@mui/icons-material/HourglassEmpty';
import ManageAmenitiesIcon from '@mui/icons-material/Event';
import VisitorsHistoryIcon from '@mui/icons-material/History';
import HelpComplaint from '@mui/icons-material/HelpCenterOutlined';
import GroupIcon from '@mui/icons-material/Group';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NoticeBoard from './NoticeBoard';
import { useCookies } from 'react-cookie';
import { API } from './api-service';
import GymSelectionModal from './GymSelectionModal';
import ScheduleBoard from './ScheduleBoard';
import MembershipBoard from './MembershipBoard';

const Dashboard = () => {
  const [Token, setToken] = useCookies(['token']);
  const [Role, setRole, deleteRole] = useCookies(['role']);
  const [countersData, setCountersData] = useState<any>();
  const [dashboardViews, setDashboardViews] = useState<any[]>([]);
  const [showGymModal, setShowGymModal] = useState(false);
  const [gyms, setGyms] = useState<string[]>([]);
  const [currentGym, SetCurrentGym, deleteCurrentGym] = useCookies(['current_gym']);
  const [gymDashboardData, setGymDashboardData] = useState<any>();

  useEffect(() => {
    if (!Token['token']) {
      window.location.href = '/';
    }
  }, [Token]);

  useEffect(() => {
    if (currentGym['current_gym']) {
      API.getGymDashboard(Token['token'], currentGym['current_gym'].id)
        .then(response => {
          setGymDashboardData(response);
        })
        .catch(error => {
          console.error('Error fetching gym dashboard data:', error);
        });
    }
  }, [currentGym, Token]);

  useEffect(() => {
    if (Token['token'] && Role['role'] === 'Society') {
      API.getDashboardCounters(Token['token']).then((resp) => {
        console.log(resp);
        setCountersData(resp);
      }).catch((error) => {
        console.error('Error fetching counters:', error);
      });

      const fetchViews = async () => {
        try {
          const views = await API.GetViews(Role['role'], Token['token']);
          if (views.length > 0) {
            setDashboardViews(views[0].dashboard_views);
          }
        } catch (error) {
          console.error('Failed to fetch views', error);
        }
      };

      fetchViews();
    } else if (Token['token'] && Role['role'] === 'Gym') {
      API.GetGyms(Token['token']).then((resp) => {
        console.log(resp);
        setGyms(resp);
        setShowGymModal(!currentGym['current_gym']);
      }).catch((error) => {
        console.error('Error fetching gyms:', error);
      });
    }
  }, [Role, Token]);

  const handleViewMore = (endpoint: string) => {
    window.location.href = endpoint;
  };

  const handleOpenGymModal = () => {
    setShowGymModal(true);
  };

  const handleCloseGymModal = () => {
    setShowGymModal(false);
  };

  const handleSelectGym = (gym: any) => {
    console.log('Selected Gym:', gym);
    SetCurrentGym('current_gym', gym);
    API.getGymDashboard(Token['token'], gym.id)
      .then(response => {
        setGymDashboardData(response);
      })
      .catch(error => {
        console.error('Error fetching gym dashboard data:', error);
      });
  };

  const dashboardItems = dashboardViews.map((view: any) => {
    switch (view.name) {
      case 'view_pending_bills':
        return {
          icon: <PendingIcon />,
          title: 'Pending Bills',
          dataKey: 'pending_bills',
          iconColor: '#1976d2',
          endpoint: view.endpoint
        };
      case 'view_amenities_booking':
        return {
          icon: <ManageAmenitiesIcon />,
          title: 'Amenities Booking',
          dataKey: 'amenities_booked',
          iconColor: '#388e3c',
          endpoint: view.endpoint
        };
      case 'view_visitor_history':
        return {
          icon: <VisitorsHistoryIcon />,
          title: 'Visitors History',
          dataKey: 'visitor_visits',
          iconColor: '#d32f2f',
          endpoint: view.endpoint
        };
      case 'view_help_and_complaint':
        return {
          icon: <HelpComplaint />,
          title: 'Helps & Complaints',
          dataKey: 'complaint_help_count',
          iconColor: '#3f48cc',
          endpoint: view.endpoint
        };
      case 'view_members':
        return {
          icon: <GroupIcon />,
          title: 'Members',
          dataKey: 'members_count',
          iconColor: '#9c27b0',
          endpoint: view.endpoint
        };
      default:
        return null;
    }
  }).filter(item => item !== null);

  return (
    <Box padding={5} sx={{ flexGrow: 1 }}>
      <Typography variant="h4" component="h1" gutterBottom color='primary.main'>
        Dashboard
      </Typography>
      {Role['role'] === 'Gym' ? <Button variant="contained" color="primary" onClick={handleOpenGymModal}>Select Gym</Button> : <></>}
      <Grid container spacing={2}>
        {Role['role'] === 'Society' && dashboardItems.map((item: any, index) => (
          <Grid item xs={12} sm={3} key={index}>
            <Paper elevation={3} sx={{
              padding: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 200,
              ":hover": { boxShadow: 10 }
            }}>
              <Typography variant="h4" sx={{ marginBottom: '10px' }}>{item.title}</Typography>
              <Box sx={{
                color: item.iconColor,
                marginBottom: 2,
                '& svg': {
                  fontSize: 60
                }
              }}>
                {item.icon}
              </Box>
              <Typography variant="h6" sx={{ marginBottom: '10px' }}>{`${countersData?.[item.dataKey] || 0} ${item.title}`}</Typography>
              <Button variant="outlined" onClick={() => handleViewMore(item.endpoint)}>View More</Button>
            </Paper>
          </Grid>
        ))}
        {Role['role'] === 'Gym' && gymDashboardData && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Paper elevation={3} sx={{
                padding: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 200,
                ":hover": { boxShadow: 10 }
              }}>
                <Typography variant="h4" sx={{ marginBottom: '10px' }}>Total Members</Typography>
                <Box sx={{
                  color: '#9c27b0',
                  marginBottom: 2,
                  '& svg': {
                    fontSize: 60
                  }
                }}>
                  <GroupIcon />
                </Box>
                <Typography variant="h6" sx={{ marginBottom: '10px' }}>{`${gymDashboardData.total_members} Members`}</Typography>
                <Button variant="outlined" onClick={() => handleViewMore('/gym-members')}>View More</Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper elevation={3} sx={{
                padding: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 200,
                ":hover": { boxShadow: 10 }
              }}>
                <Typography variant="h4" sx={{ marginBottom: '10px' }}>Total Trainers</Typography>

                <Box sx={{
                  color: '#9c27b0',
                  marginBottom: 2,
                  '& svg': {
                    fontSize: 60
                  }
                }}>
                  <GroupIcon />
                </Box>
                <Typography variant="h6" sx={{ marginBottom: '10px' }}>{`${gymDashboardData.total_trainers} Trainers`}</Typography>
                <Button variant="outlined" onClick={() => handleViewMore('/gym-trainers')}>View More</Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper elevation={3} sx={{
                padding: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 200,
                ":hover": { boxShadow: 10 }
              }}>
                <Typography variant="h4" sx={{ marginBottom: '10px' }}>Pending Bills</Typography>

                <Box sx={{
                  color: '#9c27b0',
                  marginBottom: 2,
                  '& svg': {
                    fontSize: 60
                  }
                }}>
                  <ReceiptIcon />
                </Box>
                <Typography variant="h6" sx={{ marginBottom: '10px' }}>{`${gymDashboardData.financial_summary.pending_bills} Bills`}</Typography>
                <Button variant="outlined" onClick={() => handleViewMore('/gym-bills')}>View More</Button>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Box mt={4}>
        {Role['role'] === 'Society' ? (
          <>
            <NoticeBoard />
          </>
        ) :
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ScheduleBoard token={Token['token']} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MembershipBoard token={Token['token']} />
          </Grid>
          </Grid>
        }
      </Box>

      <GymSelectionModal
        open={showGymModal}
        onClose={handleCloseGymModal}
        gyms={gyms}
        onSelect={handleSelectGym}
      />
    </Box>
  );
};

export default Dashboard;
