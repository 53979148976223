import React, { useState, useEffect } from 'react';
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/system';
import { API } from './api-service';
import { useCookies } from 'react-cookie';

const DropzoneContainer = styled('div')({
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer'
});

const ContactUs: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [topic, setTopic] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [topics, setTopics] = useState<{ id: number, name: string, is_upload: 'required' | 'optional' | 'not_required' }[]>([]);
  const [Token, , deleteToken] = useCookies(['token']);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const topicsData = await API.getContactUsTopics(Token['token']);
        setTopics(topicsData);
      } catch (error) {
        console.error('Error fetching topics:', error);
      }
    };

    fetchTopics();
  }, [Token]);

  const handleDrop = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('email_from', email);
    formData.append('mobile', mobile);
    formData.append('topic', topic);
    formData.append('title', subject);
    formData.append('subject', subject);
    formData.append('desc', description);
    formData.append('message', description);
    files.forEach((file) => {
      formData.append('attachments', file);
    });

    try {
      const response = await API.sendEmail(formData);
      console.log('Email sent:', response.data);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
  });

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <TextField
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
        required
      />
      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
        required
      />
      <TextField
        label="Mobile Number"
        value={mobile}
        onChange={(e) => setMobile(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
        required
      />
      <TextField
        select
        label="Topic"
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
        required
      >
        {topics.map((topic) => (
          <MenuItem key={topic.id} value={topic.name}>
            {topic.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
        required
      />
      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
        required
        multiline
        rows={4}
      />
      {topics.find((t) => t.name === topic)?.is_upload !== 'not_required' && (
        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <Typography>
            Drag and drop a file here, or click to select a file
          </Typography>
          {files.length > 0 && (
            <Typography variant="body2" color="textSecondary">
              {files[0].name}
            </Typography>
          )}
        </DropzoneContainer>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{ mt: 3 }}
      >
        Submit
      </Button>
    </Box>
  );
};

export default ContactUs;
