import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import ExerciseForm from './ExerciseForm'; // Ensure to import the ExerciseForm component
import DietForm from './DietForm'; // Import DietForm component
import { API } from './api-service';

interface EditScheduleModalProps {
  token: string;
  gym_id: string;
  open: boolean;
  onClose: () => void;
  schedule: any | null;
  mode: 'add' | 'edit';
  fetchSchedules: () => void;
}

const EditScheduleModal: React.FC<EditScheduleModalProps> = ({ token, gym_id, open, onClose, schedule, mode, fetchSchedules }) => {
  const [editSchedule, setEditSchedule] = useState<any>({ name: '', type: 'workout', plans: {} });
  const [type, setType] = useState<string>('workout');
  const [days, setDays] = useState<any>({});

  useEffect(() => {
    if (mode === 'add') {
      setEditSchedule({
        gym_id: 1,
        name: "",
        plans: {
          Monday: {},
          Tuesday: {},
          Wednesday: {},
          Thursday: {},
          Friday: {},
          Saturday: {},
          Sunday: {}
        },
        type: 'workout'
      });
      setType('workout');
      setDays({
        Monday: {},
        Tuesday: {},
        Wednesday: {},
        Thursday: {},
        Friday: {},
        Saturday: {},
        Sunday: {}
      });
    } else if (schedule) {
      setEditSchedule(schedule);
      setType(schedule.type || 'workout');
      setDays(schedule.plans || {});
    }
  }, [schedule, mode]);

  const handleSave = async () => {
    try {
      const updatedSchedule = {
        ...editSchedule,
        type,
        plans: days
      };

      if (mode === 'add') {
        console.log('Adding schedule:', updatedSchedule);
        const response = await API.manageSchedule(token, gym_id, undefined, updatedSchedule, 'create');
      } else if (mode === 'edit') {
        const response = await API.manageSchedule(token, gym_id, schedule.id, updatedSchedule, 'update');
        console.log('Editing schedule:', updatedSchedule);
      }
      fetchSchedules();
      onClose();
    } catch (error) {
      console.error("Failed to save schedule:", error);
    }
  };

  const handleAddExercise = (day: string, exercise: any) => {
    setDays((prevState: any) => ({
      ...prevState,
      [day]: { ...prevState[day], ...exercise }
    }));
  };

  const handleDeleteExercise = (day: string, exerciseName: string) => {
    setDays((prevState: any) => {
      const { [exerciseName]: _, ...remainingExercises } = prevState[day];
      return {
        ...prevState,
        [day]: remainingExercises
      };
    });
  };

  const handleAddDietItem = (day: string, item: any) => {
    setDays((prevState: any) => ({
      ...prevState,
      [day]: { ...prevState[day], ...item }
    }));
  };

  const handleDeleteDietItem = (day: string, itemName: string) => {
    setDays((prevState: any) => {
      const { [itemName]: _, ...remainingItems } = prevState[day];
      return {
        ...prevState,
        [day]: remainingItems
      };
    });
  };

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    const newType = event.target.value;
    setType(newType);
    setEditSchedule((prev: any) => ({ ...prev, type: newType }));
    setDays({
      Monday: {},
      Tuesday: {},
      Wednesday: {},
      Thursday: {},
      Friday: {},
      Saturday: {},
      Sunday: {}
    });
  };

  const handleSchedulenameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEditSchedule((prev: any) => ({ ...prev, name: value }));
  };

  const renderForm = () => {
    if (type === 'workout') {
      return Object.keys(days).map(day => (
        <Box key={day} sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>{day.charAt(0).toUpperCase() + day.slice(1)}</Typography>
          <ExerciseForm
            exercises={editSchedule}
            day={day}
            onAddExercise={handleAddExercise}
            onDeleteExercise={handleDeleteExercise}
            exercisesForDay={days[day]}
          />
        </Box>
      ));
    } else if (type === 'diet') {
      return Object.keys(days).map(day => (
        <Box key={day} sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom>{day.charAt(0).toUpperCase() + day.slice(1)}</Typography>
          <DietForm
            diet={editSchedule}
            day={day}
            onAddItem={handleAddDietItem}
            onDeleteItem={handleDeleteDietItem}
            itemsForDay={days[day]}
          />
        </Box>
      ));
    }
    return null;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-edit-modal-title"
      aria-describedby="add-edit-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight: '80vh',
        overflowY: 'auto'
      }}>
        <Typography variant="h6" gutterBottom>
          {mode === 'add' ? 'Add New Schedule' : 'Edit Schedule'}
        </Typography>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel id="schedule-type-label">Type</InputLabel>
          <Select
            labelId="schedule-type-label"
            value={type}
            onChange={handleTypeChange}
            label="Type"
          >
            <MenuItem value="workout">Workout</MenuItem>
            <MenuItem value="diet">Diet</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          type="text"
          name="name"
          value={editSchedule.name}
          onChange={handleSchedulenameChange}
          sx={{
            mb: 3,
            input: { color: 'primary.main' },
            '& label': { color: 'blue' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'blue' },
              '&:hover fieldset': { borderColor: 'lightblue' },
              '&.Mui-focused fieldset': { borderColor: 'blue' },
            }
          }}
        />
        {renderForm()}
        <Button onClick={handleSave} variant="contained" color="primary">
          {mode === 'add' ? 'Add Schedule' : 'Save Changes'}
        </Button>
      </Box>
    </Modal>
  );
};

export default EditScheduleModal;
