import React, { useState, useEffect } from 'react';
import { Button, Box, Grid } from '@mui/material';
import DataTable from './DataTable_Server';
import { API } from './api-service';
import { useCookies } from 'react-cookie';
import EdituserDialog from './EdituserDialog';
import { UUID } from 'crypto';
import GeneralizedFilter from './Filter';

interface Guard {
  id: number;
  name: string;
  status: boolean;
  lastLoggedIn: string;
  Actions: string[];
}

const ManageUsers: React.FC = () => {
  const [data, setData] = useState<Guard[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortField, setSortField] = useState<string>('username');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [Token, setToken] = useCookies(['token']);
  const [openEditDialogue, setOpenEditDialogue] = useState(false)
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [userId, setUserId] = useState("")

  useEffect(() => {
    const checkAuth = async () => {
      if (!Token['token']) window.location.href = '/';
    };
    checkAuth();
  }, [Token]);

  const fetchData = async (queryParams: any = {}) => {
    if (!Token['token']) return;
    console.log(queryParams)
    const input = {
      role: 'User',
      token: Token['token'],
      search: searchQuery,
      sort_by: sortField,
      order: sortOrder,
      page,
      page_size: rowsPerPage,
      ...queryParams,
    }
    console.log(input)
    const result = await API.userListBasedOnRoles(input);

    if (result) {
      const users = result.results.map((item: any) => ({
        // user_id: item.user_id,
        UserId: item.id,
        Name: item.Name || 'N/A',
        Wing: item.Wing,
        Flats: item.Flats,
        Phone: item.Phone,
        Status: item.Status,
        Actions: ['edit'],
      }));
      console.log(result.results)
      setData(users);
      setTotalRows(result.total_results);
    }
  };

  useEffect(() => {
    fetchData();
  }, [Token, page, rowsPerPage, searchQuery, sortField, sortOrder]);

  useEffect(() => {
    if (data.length > 0) {
      setHeaders([...Object.keys(data[0])]);
    }
  }, [data]);

  const handleQueryType = (type: string, value: any) => {
    const queryParams: any = {};
    switch (type) {
      case 'sort':
        setSortField(value.property);
        setSortOrder(value.order);
        queryParams.sortField = value.property;
        queryParams.sortOrder = value.order;
        break;
      case 'search':
        setSearchQuery(value);
        queryParams.search = value;
        break;
      case 'page':
        setPage(value);
        queryParams.page = value;
        break;
      case 'rowsPerPage':
        setRowsPerPage(value);
        setPage(0);
        queryParams.page = 0;
        queryParams.pageSize = value;
        break;
      case 'booleanChange':
        handleBooleanChange(value.id, value.header, value.value);
        return;
      case 'edit':
        handleEdit(value);
        return;
      case 'delete':
        handleDelete(value);
        return;
      default:
        break;
    }
    fetchData(queryParams);
  };

  const handleBooleanChange = async (id: number, header: string, value: boolean) => {
    console.log(id, header, value)
    var request_body = {
      token: Token["token"],
      status: value
    }
    try {
      await API.updateUserStatus(id, request_body, "PUT");
      await fetchData();
    } catch (error) {
      console.log(error);
    }
    console.log("booleaan change")
  };

  const handleEdit = (id: any) => {
    console.log("id ", id)
    setUserId(id)
    setOpenEditDialogue(true);

  };

  const handleDelete = (id: number) => {
    console.log(`Delete clicked for row with id: ${id}`);
  };

  const handleFilterChange = (selectedFilters: Record<string, any>, filterName: string) => {
    setFilters(prevFilters => ({ ...prevFilters, ...selectedFilters }));
    console.log(selectedFilters)
    fetchData({ ...filters, ...selectedFilters });
  };
  
  if (!Token['token']) {
    return <></>;
  }

  return (
    <Box padding={5}>
      <h1>Manage Users</h1>

      {/* <Button variant="outlined" sx={{ marginBottom: '10px' }}>Add User</Button> */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <DataTable
            headers={headers}
            data={data}
            onEdit={handleEdit}
            onDelete={handleDelete}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            queryType={handleQueryType}
            featureName="manage users"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <GeneralizedFilter feature_type="wings_list" filterName="Wing" onFilterChange={handleFilterChange} token={Token['token']} />
        </Grid>
      </Grid>

      <EdituserDialog
        open={openEditDialogue}
        onClose={() => setOpenEditDialogue(false)}
        token={Token['token']}
        user_id={userId}
      ></EdituserDialog>
    </Box>

  );
};

export default ManageUsers;
