import React, { useState, useEffect } from "react";
import { AppBar, IconButton, Toolbar, useTheme, useMediaQuery, Menu, MenuItem, ListItemIcon, ListItemText, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import { useCookies } from "react-cookie";
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToApp from '@mui/icons-material/ExitToApp';
import NavigationList from "./navigationlist";
import { API } from "./api-service";
import UserProfileDialog from "./UserProfileDialog";

function NavBar() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [Token, , deleteToken] = useCookies(['token']);
    const [anchorEl, setAnchorEl] = useState(null);
    const [IsOpenUserInfoDialog, setIsOpenUserInfoDialog] = useState(false);
    const [UserData, setUserData] = useState(null);
    const [Role, setRole,deleteRole] = useCookies(['role']);
    const [currentGym, SetCurrentGym,deleteCurrentGym] = useCookies(['current_gym']);
    const logo = '/media/logo.png';
    const user = '/media/user.png';

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuOpen = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        console.log(Token['token'])
        const response = API.Logout(Token['token']).then(
            resp => (window.location.href = '/')
        )
        deleteCurrentGym('current_gym')
        deleteRole('role')
        console.log(response)
        deleteToken('token');
    };

    const handleViewProfile = async () => {
        setIsOpenUserInfoDialog(true);
        try {
            const resp = await API.fetchUserData(Token['token']);
            setUserData(resp);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        }
    };

    const drawer = (
        <div>
            <IconButton
                onClick={handleDrawerToggle}
                sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: theme.palette.text.primary,
                    zIndex: 999,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    }
                }}
            >
                <CloseIcon sx={{ fontSize: 30 }} />
            </IconButton>
            <NavigationList isMobile={isMobile} />
        </div>
    );

    return (
        <AppBar position="sticky" sx={{ backgroundColor: '#eeeeee' }}>
            <Toolbar sx={{ alignItems: 'center', display: 'flex' }}>
                <img src={logo} alt="Logo" style={{ height: 100, marginRight: 'auto' }} />
                <div style={{ flexGrow: 1 }}></div>
                {Token['token'] && isMobile ? (
                    <div>
                        <IconButton onClick={handleMenuOpen} sx={{ padding: 0 }}>
                            <img src={user} alt="user-image" style={{ height: 45 }} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleMenuClose}>
                                <ListItemIcon>
                                    <AccountCircle />
                                </ListItemIcon>
                                <ListItemText primary="View Profile" onClick={handleViewProfile} />
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose}>
                                <ListItemIcon>
                                    <ExitToApp />
                                </ListItemIcon>
                                <ListItemText primary="Logout" onClick={handleLogout} />
                            </MenuItem>
                        </Menu>
                    </div>
                ) : null}
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: isMobile ? 'block' : 'none', color: 'text.primary', alignSelf: 'center' }}
                >
                    <MenuIcon sx={{ fontSize: 45 }} />
                </IconButton>
                {!isMobile && (
                    <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                        <NavigationList isMobile={isMobile} />
                        {Token['token'] ? (
                            <div>
                                <IconButton onClick={handleMenuOpen} sx={{ padding: 0 }}>
                                    <img src={user} alt="user-image" style={{ height: 45 }} />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={handleMenuClose}>
                                        <ListItemIcon>
                                            <AccountCircle />
                                        </ListItemIcon>
                                        <ListItemText primary="View Profile" onClick={handleViewProfile} />
                                    </MenuItem>
                                    <MenuItem onClick={handleMenuClose}>
                                        <ListItemIcon>
                                            <ExitToApp />
                                        </ListItemIcon>
                                        <ListItemText primary="Logout" onClick={handleLogout} />
                                    </MenuItem>
                                </Menu>
                            </div>
                        ) : null}
                    </div>
                )}
            </Toolbar>

            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 300, paddingTop: '10px', paddingLeft: '10px' },
                    '& .MuiPaper-root': { backgroundColor: 'background.paper', color: 'black' },
                }}
            >
                {drawer}
            </Drawer>
            <UserProfileDialog
                open={IsOpenUserInfoDialog}
                onClose={() => setIsOpenUserInfoDialog(false)}
                data={UserData}
            />
        </AppBar>
    );
}

export default NavBar;
