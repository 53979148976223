
// const Token = "aca4635f4bc1decff97ff6e9998d6b5f3a5def00"
// const BaseUrl = "http://localhost:8000/api/"
// const GymURL = "http://localhost:8000/gym/"
const BaseUrl = "http://ec2-3-111-214-136.ap-south-1.compute.amazonaws.com/api/"
const GymURL = "http://ec2-3-111-214-136.ap-south-1.compute.amazonaws.com/gym/"
export class API {
  static LoginApiCall(body) {
    return fetch(`${BaseUrl}login?role=${body.role}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(resp => resp.json())
  }

  static RegisterApiCall(body) {
    return fetch(`${BaseUrl}register/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(resp => resp.json())
  }

  static userListBasedOnRoles(input) {
    const url = new URL(`${BaseUrl}user-list-based-on-roles/`);
    // const params = {
    //   role,
    //   search: input.search || '',
    //   sort_by: input.sortField || 'username',
    //   order: input.sortOrder || 'asc',
    //   page: input.page ? page : 1,
    //   page_size: input.pageSize || 10,
    //   ...input.
    // };

    Object.keys(input).forEach(key => url.searchParams.append(key, input[key]));

    return fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${input.token}`,
      },
    }).then(resp => resp.json());
  }

  static fetchNotices = async (Token) => {
    const response = await fetch('notices', {
      headers: {
        'Authorization': `Bearer ${Token['token']}`
      }
    });
    const data = await response.json();
    return data;
  };

  static NoticeboardAction(action, body = {}, id = null) {
    let url = `${BaseUrl}society/noticeboard`;
    let method = 'GET';

    switch (action) {
      case 'fetch':
        method = 'GET';
        break;
      case 'create':
        method = 'POST';
        break;
      case 'update':
        method = 'PUT';
        if (id) {
          url += `${id}/`;
        }
        break;
      case 'delete':
        method = 'DELETE';
        if (body.id) {
          url += `/${body.id}/`;
        }
        break;
      default:
        throw new Error('Invalid action type');
    }

    return fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${body.token}`,
      },
      body: method !== 'GET' && method !== 'DELETE' ? JSON.stringify(body) : null,
    }).then(
      resp => {
        if (method === 'DELETE') {
          return [];
        }
        else {
          return resp.json();
        }
      }
    );
  }

  static async fetchMaintenanceData(token, params) {
    const url = new URL(`${BaseUrl}maintenance_data_paginate`);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  static async updateMaintenanceBill(token, billId, data) {
    const url = `${BaseUrl}maintenance_data/update-maintenance-bill/${billId}/`;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  static extractMultipleProperties(arr, properties) {
    return arr.map(item => {
      const extracted = {};
      properties.forEach(property => {
        extracted[property] = item[property];
      });
      return extracted;
    });
  }

  static async fetchFilterFields(feature_type, token) {
    var url = undefined;
    if (feature_type === "wings_list") {
      url = `${BaseUrl}wings_list`;
    }
    console.log(url)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // if(feature_type === "wings"){
    //   return this.extractMultipleProperties(response.json())
    // }
    return response.json();
  }

  // static async uploadFile(feature_type, token, formData) {

  // }

  // static async downloadFile(feature_type, token) {

  // }

  static async fetchAmenitieseData(token, params) {
    const url = new URL(`${BaseUrl}amenities-bookings/`);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }
  static async fetchVisitorsData(token, params) {
    const url = new URL(`${BaseUrl}visitor-history-paginate/`);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  static async fetchAmenityList(token) {
    const url = new URL(`${BaseUrl}societies-amenities/`);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  static async fetchAmenityDataUser(token, user_id) {
    const url = new URL(`${BaseUrl}user_wise_amenities?user_id=${user_id}`);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  static async updateAmenityAccess(request_body, flat_id, token) {
    console.log(request_body)
    const url = new URL(`${BaseUrl}update-access/flat/${flat_id}/`);
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request_body)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  static async updateUserStatus(id, request_body, method) {
    console.log(request_body)
    const url = new URL(`${BaseUrl}users/${id}/`);
    const response = await fetch(url, {
      method: method,
      headers: {
        'Authorization': `JWT ${request_body.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request_body)
    });
    if (method === "DELETE") {
      return [];
    }
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  static async updateAmenityBooking(id, request_body) {
    console.log(request_body)
    const url = new URL(`${BaseUrl}amenities-bookings/${id}`);
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `JWT ${request_body.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request_body)
    });
    return response;
  }

  static async uploadFile(featureType, token, formData) {
    console.log(formData);
    console.log(featureType);

    let url;
    if (featureType === 'bill_history') {
      url = new URL(`${BaseUrl}maintenance_bill_bulk_upload`);
    } else if (featureType === 'maintenance_fields') {
      url = new URL(`${BaseUrl}maintenance_bulk_upload`);
    } else {
      throw new Error('Invalid featureType');
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
        },
        body: formData
      });
      return response;
    } catch (error) {
      console.error('Failed to upload file:', error);
      throw error;
    }
  }

  // static async updateMaintenanceBill(request_body,id, token) {
  //   url = new URL(`${BaseUrl}maintenance_data/update-maintenance-bill/${id}/`);
  //   console.log(request_body)
  //   console.log(id)
  //   try {
  //     const response = await fetch(url, {
  //       method: 'PUT',
  //       headers: {
  //         'Authorization': `JWT ${token}`,
  //       },
  //       body: request_body
  //     });
  //     return response;
  //   } catch (error) {
  //     console.error('Failed to upload file:', error);
  //     throw error;
  //   }
  // }

  static async fetchMaintenanceFieldsData(token, params) {
    const url = new URL(`${BaseUrl}maintenance_fields_data_paginate`);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  static async fetchHelpComplaintsData(token, params) {
    const url = new URL(`${BaseUrl}help_complaint_paginate_view`);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }
  static async updateDeleteHelpComplaintsData(token, method, type, id, data) {
    const url = `${BaseUrl}complaints/${type}/${id}/`;

    const response = await fetch(url, {
      method: method,
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  static fetchUserData = async (token) => {
    const url = new URL(`${BaseUrl}user_data`);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  };

  static async changePassword(token, oldPassword, newPassword) {
    const url = new URL(`${BaseUrl}reset-password/`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ old_password: oldPassword, new_password: newPassword })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  }

  static async Logout(token) {
    console.log(token)
    const url = new URL(`${BaseUrl}logout/`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: token })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  }
  static async checkAuthStatusAndRedirectToLogin(token) {
    const url = new URL(`${BaseUrl}check_auth/`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  }

  static async getSocieties() {
    const url = new URL(`${BaseUrl}societies`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  }

  static async getWings() {
    const url = new URL(`${BaseUrl}wings`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  }
  static async getDashboardCounters(token) {
    const url = new URL(`${BaseUrl}dashboard_counters/`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  }

  static async getContactUsTopics(token) {
    const url = new URL(`${BaseUrl}list-dropdown-topics/`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  }

  static async sendEmail(formData) {
    try {
      const response = await fetch(`${BaseUrl}send_email/`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to send email');
      }
      return await response.json();
    } catch (error) {
      throw new Error(`API Error: ${error.message}`);
    }
  };

  static async GetViews(role, Token) {
    console.log(role)
    try {
      const response = await fetch(`${BaseUrl}views-by-role/?role=${role}`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${Token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to get Role based views!');
      }
      return await response.json();
    } catch (error) {
      throw new Error(`API Error: ${error.message}`);
    }
  };

  static async GetGyms(Token) {
    try {
      const response = await fetch(`${GymURL}gym_list/`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${Token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to get Role based views!');
      }
      return await response.json();
    } catch (error) {
      throw new Error(`API Error: ${error.message}`);
    }
  };

  static async getGymDashboard(Token, gym_id) {
    try {
      const response = await fetch(`${GymURL}dashboard/${gym_id}/`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${Token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to get Gym Dashboard data!');
      }
      return await response.json();
    } catch (error) {
      throw new Error(`API Error: ${error.message}`);
    }
  };

  static async GymMembers(Token, gym_id, method, data, member_id) {
    console.log(data)
    const url = new URL(member_id
      ? `${GymURL}members/${gym_id}/${member_id}/`
      : `${GymURL}members/${gym_id}/`);
    if(method=='GET'){
      Object.keys(data).forEach(key => url.searchParams.append(key, data[key]));
    }
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Authorization': `JWT ${Token}`,
          'Content-Type': 'application/json'
        },
        body: (method === 'POST' || method==='PUT') ? JSON.stringify(data) : null
      });

      if (!response.ok) {
        throw new Error('Failed to perform the requested action!');
      }

      // Handle response differently for GET, PUT, POST
      if (method === 'GET') {
        return await response.json();
      } else if (method === 'POST') {
        return await response.json();
      } else if (method === 'PUT') {
        return await response.json();
      } else if (method === 'DELETE') {
        return response.status === 204 ? 'Deleted successfully' : await response.json();
      }
    } catch (error) {
      throw new Error(`API Error: ${error.message}`);
    }
  }
  static async getGymSchedules(Token, gym_id,schedule_id ,member_id, username = '') {
    const url = new URL(member_id
      ? `${GymURL}schedules/?member_id=${member_id}&username=${username}`
      : `${GymURL}schedules/${schedule_id}?gym_id=${gym_id}`);
      
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${Token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch gym schedules!');
      }

      return await response.json();
    } catch (error) {
      throw new Error(`API Error: ${error.message}`);
    }
  }

  static async manageSchedule(Token, gym_id, schedule_id = null, scheduleData = null, action) {
    let url = `${GymURL}schedules/`;
    const methodMap = {
      'create': 'POST',
      'update': 'PUT',
      'delete': 'DELETE'
    };
  
    const method = methodMap[action];
    if (!method) {
      throw new Error('Invalid action type');
    }
  
      if (method === 'DELETE' && schedule_id) {
      url = `${url}${schedule_id}?gym_id=${gym_id}`;
      console.log(url)
    }
  
    const bodyData = {
      gym_id: gym_id,
      ...(action !== 'create' && { schedule_id: schedule_id }),
      ...(scheduleData && { ...scheduleData })
    };
  
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Authorization': `JWT ${Token}`,
          'Content-Type': 'application/json'
        },
        body: method !== 'DELETE' ? JSON.stringify(bodyData) : undefined
      });
  
      if (!response.ok) {
        const errorDetails = await response.text(); 
        throw new Error(`Failed to ${action} schedule: ${errorDetails}`);
      }
  
      return method === 'DELETE' ? {} : await response.json();
    } catch (error) {
      throw new Error(`API Error: ${error.message}`);
    }
  }
  static async manageTrainer(Token, gym_id, trainer_id = null, trainerData = null, action) {
    let url = `${GymURL}trainer/`;
    const methodMap = {
      'create': 'POST',
      'update': 'PUT',
      'delete': 'DELETE',
      'get': 'GET'
    };
  
    const method = methodMap[action];
    if (!method) {
      throw new Error('Invalid action type');
    }
  
    if (method === 'DELETE' && trainer_id) {
      url = `${url}${trainer_id}?gym_id=${gym_id}`;
    } else if (method === 'GET' && trainer_id) {
      url = `${url}${trainer_id}`;
    }
  
    const bodyData = {
      gym_id: gym_id,
      ...(action !== 'create' && trainer_id && { trainer_id: trainer_id }),
      ...(trainerData && { ...trainerData })
    };
  
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Authorization': `JWT ${Token}`,
          'Content-Type': 'application/json'
        },
        body: method !== 'GET' && method !== 'DELETE' ? JSON.stringify(bodyData) : undefined
      });
  
      if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(`Failed to ${action} trainer: ${errorDetails}`);
      }
  
      return method === 'DELETE' ? {} : await response.json();
    } catch (error) {
      throw new Error(`API Error: ${error.message}`);
    }
  }
  
  static async memberdata(token,member_id){
    const url = `${GymURL}member/${member_id}`
    try{
      const response = await fetch(url,{
        method:"GET",
        headers:{
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json'
        }
      })
      if(!response.ok){
        const errorDetails = await response.text();
        throw new Error(`Failed to get trainer: ${errorDetails}`);
      }
      return response.json();
    }
    catch(error){
      throw new Error(`API Error: ${error.message}`);
    }
  }

  static async getTrainerDataPaginated(token, params) {
    const url = new URL(`${GymURL}get_trainer_paginated`);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  static async Memberships(Token, gym_id, method, data , membership_id ) {
      const url = new URL(membership_id
        ? `${GymURL}memberships/${membership_id}/`
        : `${GymURL}memberships/`);

      console.log(url)
      if (method === 'GET' && gym_id) {
        url.searchParams.append('gym', gym_id);
        if (data) {
          Object.keys(data).forEach(key => url.searchParams.append(key, data[key]));
        }
      }
  
      try {
        const response = await fetch(url, {
          method: method,
          headers: {
            'Authorization': `JWT ${Token}`,
            'Content-Type': 'application/json'
          },
          body: (method === 'POST' || method === 'PUT') ? JSON.stringify(data) : null
        });
  
        if (!response.ok) {
          throw new Error('Failed to perform the requested action!');
        }
  
        if (method === 'GET') {
          return await response.json();
        } else if (method === 'POST') {
          return await response.json();
        } else if (method === 'PUT') {
          return await response.json();
        } else if (method === 'DELETE') {
          return response.status === 204 ? 'Deleted successfully' : await response.json();
        }
      } catch (error) {
        throw new Error(`API Error: ${error.message}`);
      }
    }
  
    static async fetchTrainerMemberData(token,id) {
      const url = new URL(`${GymURL}get_member_under_trainer_paginated/${id}`);
      // Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      return response.json();
    }  
    static async fetchBillsData(token,id,params) {
      const url = new URL(`${GymURL}bills/${id}`);
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      return response.json();
    }  

    static async fetchWingList(society){
      const url = new URL(`${BaseUrl}wings`)
      url.searchParams.append('society',society);
      const response =  await fetch(url,{
        method:'GET',
        headers:{
          'Content-Type':'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    }
}

