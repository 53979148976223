import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CookiesProvider, useCookies } from 'react-cookie';
import AuthForm from './components/AuthForm';
import { ThemeProvider } from '@emotion/react';
import theme from './Theme/theme';
import Layout from './components/Layout';
import Dashboard from './components/Dashboard';
import ManageGuards from './components/ManageGuards';
import ManageUsers from './components/ManageUsers';
import ManageMaintenance from './components/Maintenance';
import AmenitiesDetails from './components/AmenitiesDetails';
import VisitorsHistory from './components/VisitorsHistory';
import HelpComplaint from './components/HelpComplaint';
import { API } from './components/api-service';
import { error } from 'console';
import ContactUs from './components/ContactUs';
import GymMembers from './components/GymMembers';
import TrainerData from './components/TrainerData';
import BillReciept from './components/BillReciept';

function Router() {

  const [isLoginView, setIsLoginView] = useState(true)
  const [Token, setToken, deleteToken] = useCookies(['token'])

  // const checkAuth = async () => {
  //   API.checkAuthStatusAndRedirectToLogin(Token['token'])
  //   .then(
  //     (resp)=>{
  //       if(resp=="JWT token has expired"){
  //         deleteToken('token');
  //       }
  //     }
  //     )
  //     .catch(error=>{
  //       deleteToken('token');
  //     })
  //     if (!Token['token']) window.location.href = '/';
  // };
  // checkAuth();

  const token = Token['token']
  if (token) {
    setInterval(() => {
      API.checkAuthStatusAndRedirectToLogin(Token['token'])
        .then(
          (resp) => {
            if (resp == "JWT token has expired") {
              deleteToken('token');
            }
          }
        )
        .catch(error => {
          deleteToken('token');
        })
    }, 15 * 60 * 1000);
  }
  const routing = (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Layout>
          <CookiesProvider>
            <Routes>
              <Route path='/' Component={AuthForm}></Route>
              <Route path='/home' Component={Dashboard}></Route>
              <Route path='/guard' Component={ManageGuards}></Route>
              <Route path='/user' Component={ManageUsers}></Route>
              <Route path='/maintenance' Component={ManageMaintenance}></Route>
              <Route path='/amenities' Component={AmenitiesDetails}></Route>
              <Route path='/visitor_history' Component={VisitorsHistory}></Route>
              <Route path='/help_and_complaint' Component={HelpComplaint}></Route>
              <Route path='/contact' Component={ContactUs}></Route>
              <Route path='/gym-members' Component={GymMembers}></Route>
              <Route path='/gym-trainers' Component={TrainerData}></Route>
              <Route path='/gym-bills' Component={BillReciept}></Route>
            </Routes>
          </CookiesProvider>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>

  );
  return routing;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<Router />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
