import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { API } from './api-service';
import { useCookies } from 'react-cookie';

interface RegisterGuardModalProps {
  open: boolean;
  onClose: () => void;
}

const RegisterGuardModal: React.FC<RegisterGuardModalProps> = ({ open, onClose }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [wing, setWing] = useState('');
  const [society] = useCookies(['society']);
  const [Token] = useCookies(['token']);
  const [wingList, setWingList] = useState([]);

  useEffect(() => {
    if (open) {
      fetchWingsList();
    }
  }, [open]);

  const handleSubmit = async () => {
    const guardData = {
      phone,
      role: 'Security',
      society: society['society'],
      wing,
      username,
      email,
    };
    console.log(guardData);
    try {
      await API.RegisterApiCall(guardData);
      onClose();
    } catch (error) {
      console.error('Error registering guard:', error);
    }
  };

  const fetchWingsList = async () => {
    try {
      const wings_list: any = await API.fetchFilterFields("wings_list", Token['token']);
      setWingList(wings_list);
    } catch (error) {
      console.error('Error fetching wings list:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Register New Guard</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Username"
          type="text"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Phone Number"
          type="text"
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Wing</InputLabel>
          <Select
            value={wing}
            onChange={(e) => setWing(e.target.value as string)}
            label="Wing"
          >
            {wingList.map((wingItem: any) => (
              <MenuItem key={wingItem.id} value={wingItem.name}>
                {wingItem.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Register
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterGuardModal;
