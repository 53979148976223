import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import DataTable from './DataTable_Server'; // Adjust the path if necessary
import { API } from './api-service';
import { useCookies } from 'react-cookie';
import { UUID } from 'crypto';
import RegisterGuardModal from './RegisterGuardModal';

interface Guard {
  id: number;
  name: string;
  status: boolean;
  lastLoggedIn: string;
  Actions: string[];
}

const ManageGuards: React.FC = () => {
  const [data, setData] = useState<Guard[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortField, setSortField] = useState<string>('username');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [Token, setToken] = useCookies(['token']);
  const [openEditDialogue, setOpenEditDialogue] = useState(false)


  useEffect(() => {
    const checkAuth = async () => {
      if (!Token['token']) window.location.href = '/';
    };
    checkAuth();
  }, [Token]);

  const fetchData = async (queryParams: any = {}) => {
    if (!Token['token']) return;
    
    const input = {
      role: 'Security',
      token: Token['token'],
      search: searchQuery,
      sort_by: sortField,
      order: sortOrder,
      page,
      page_size: rowsPerPage,
      ...queryParams,
    }
    const result = await API.userListBasedOnRoles(input);

    if (result) {
      const guards = result.results.map((item: any) => ({
        // user_id: item.user_id,
        UserId: item.id,
        Name: item.Name || 'N/A',
        Wing: item.Wing,
        Phone: item.Phone,
        Status: item.Status,
        Actions: ['delete'],
      }));
      console.log(result.results)
      setData(guards);
      setTotalRows(result.total_results);
    }
  };

  useEffect(() => {
    fetchData();
  }, [Token, page, rowsPerPage, searchQuery, sortField, sortOrder]);

  useEffect(() => {
    if (data.length > 0) {
      setHeaders([...Object.keys(data[0])]);
    }
  }, [data]);

  const handleQueryType = (type: string, value: any) => {
    const queryParams: any = {};
    switch (type) {
      case 'sort':
        setSortField(value.property);
        setSortOrder(value.order);
        queryParams.sortField = value.property;
        queryParams.sortOrder = value.order;
        break;
      case 'search':
        setSearchQuery(value);
        queryParams.search = value;
        break;
      case 'page':
        setPage(value);
        queryParams.page = value;
        break;
      case 'rowsPerPage':
        setRowsPerPage(value);
        setPage(0);
        queryParams.page = 0;
        queryParams.pageSize = value;
        break;
      case 'booleanChange':
        handleBooleanChange(value.id, value.header, value.value);
        return;
      case 'edit':
        handleEdit(value);
        return;
      case 'delete':
        handleDelete(value);
        return;
      default:
        break;
    }
    fetchData(queryParams);
  };

  const handleBooleanChange = async (id: number, header: string, value: boolean) => {
    console.log(id, header, value)
    var request_body = {
      token: Token["token"],
      status: value
    }
    try {
      await API.updateUserStatus(id,request_body,"PUT");
      await fetchData();
      // const updatedData = data.map(item => item.id === id ? { ...item, [header]: value } : item);
      // setData(updatedData);
    } catch (error) {
      console.log(error);
    }
    console.log("booleaan change")
  };

  const handleEdit = (id: UUID) => {
    console.log(`Edit clicked for row with id: ${id}`);
  };

  const handleDelete =async (id: UUID) => {
    var request_body = {
      token: Token["token"],
    }
    console.log(`Delete clicked for row with id: ${id}`);
    await API.updateUserStatus(id,request_body,"DELETE");
    await fetchData();
  };

  const addGuards = async () =>{
    setOpenEditDialogue(true);
  }

  const handleModalClose = async () =>{
    await fetchData()
    setOpenEditDialogue(false);
  }
  if (!Token['token']) {
    return <></>;
  }

  return (
    <Box>
      <Box padding={5}>
      <h1>Manage Security Guards</h1>
      <Button variant="outlined" sx={{ marginBottom: '10px' }} onClick={addGuards}>Add Security Guards</Button>
      <DataTable
        headers={headers}
        data={data}
        onEdit={handleEdit}
        onDelete={handleDelete}
        rowsPerPage={rowsPerPage}
        totalRows={totalRows}
        queryType={handleQueryType}
        featureName="manage guards"
      />
    </Box>
    <RegisterGuardModal
      open = {openEditDialogue}
      onClose={() => handleModalClose()}
    />
    </Box>
  );
};

export default ManageGuards;
