import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@mui/material';
import { API } from './api-service';
import { useCookies } from 'react-cookie';

const HelpComplainEditDialog: React.FC<{ open: boolean, onClose: () => void, row: any,onSave:() => void }> = ({ open, onClose, row,onSave }) => {
    const [Status, setStatus] = useState('');
    const [Token, setToken] = useCookies(['token']);

    useEffect(() => {
        if (row) {
            console.log(row)
            setStatus(row.status || '');
        }
    }, [row]);

    const handleSave = () => {
        var request_body = {
            status: Status,
            last_updated_at: new Date()
            // payment_date: 
        };
        const response = API.updateDeleteHelpComplaintsData(Token['token'],'PUT',row.type,row.id, request_body);
        console.log(response);
        onSave();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm'>
            <DialogTitle >Help & Complaint Status</DialogTitle>
            <DialogContent>
                <TextField
                    select
                    label="Status"
                    value={Status}
                    onChange={(e) => setStatus(e.target.value)}
                    fullWidth
                    sx={{marginTop: '10px'}}
                >[('Raised', 'Raised'),('Closed', 'Closed'),('Reopened', 'Reopened'),('Solved', 'Solved')]
                    <MenuItem value="Raised">Raised</MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                    <MenuItem value="Reopened">Reopened</MenuItem>
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default HelpComplainEditDialog;
