import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Paper, Button, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { API } from './api-service';
import { useCookies } from 'react-cookie';
import EditScheduleModal from './EditScheduleModal';
import ViewScheduleModal from './ViewScheduleModal';

interface ScheduleBoardProps {
  token: string;
}

const ScheduleBoard: React.FC<ScheduleBoardProps> = ({ token }) => {
  const [schedules, setSchedules] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editSchedule, setEditSchedule] = useState<any | null>(null);
  const [mode, setMode] = useState<'add' | 'edit'>('add');
  const [currentGym] = useCookies(['current_gym']);
  const [selectedSchedule, setSelectedSchedule] = useState<any | null>(null);

  const schedulesPerPage = 4;

  useEffect(() => {
    const checkAuth = async () => {
      if (!token) window.location.href = '/';
    };
    checkAuth();
  }, [token]);

  const fetchSchedules = async () => {
    if (token && currentGym['current_gym']) {
      try {
        const schedules = await API.getGymSchedules(token, currentGym['current_gym'].id, '', '', '');
        setSchedules(schedules || []);
      } catch (error) {
        setSchedules([]);
        console.error("Failed to fetch schedules:", error);
      }
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [token, currentGym]); // Fetch schedules when token or currentGym changes

  const handlePrevious = () => {
    setPage(prevPage => Math.max(prevPage - 1, 0));
  };

  const handleNext = () => {
    setPage(prevPage => Math.min(prevPage + 1, Math.floor(schedules.length / schedulesPerPage)));
  };

  const startIndex = page * schedulesPerPage;
  const displayedSchedules = schedules.slice(startIndex, startIndex + schedulesPerPage);

  const openEditModal = (schedule: any) => {
    setEditSchedule(schedule);
    setMode('edit');
    setEditModalOpen(true);
  };

  const openAddModal = () => {
    setEditSchedule(null);
    setMode('add');
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    fetchSchedules(); // Fetch updated schedules after closing modal
  };

  const handleView = (schedule: any) => {
    setSelectedSchedule(schedule);
  };

  const handleDelete = async (schedule: any) => {
    if (token && currentGym['current_gym']) {
      try {
        await API.manageSchedule(token, currentGym['current_gym'].id, schedule.id, null, "delete");
        fetchSchedules();
      } catch (error) {
        console.error("Failed to delete schedule:", error);
      }
    }
  };

  return (
    <Box>
      <Typography variant="h5" component="h2" gutterBottom color='primary.main'>
        Schedule Board
        <IconButton aria-label="add" color="primary" onClick={openAddModal}>
          <AddIcon />
        </IconButton>
      </Typography>
      <Divider />
      <Box sx={{ maxHeight: '500px', overflowY: 'auto', mt: 2 }}>
        <Grid container spacing={2}>
          {displayedSchedules.map(schedule => (
            <Grid item xs={12} md={6} key={schedule.id}>
              <Paper elevation={3} sx={{
                position: 'relative',
                marginBottom: 2,
                padding: 2,
                borderRadius: 2,
                border: '1px solid #ccc',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                ":hover": { boxShadow: 10 }
              }}>
                <Box>
                  <Typography variant="h5" color='text.primary'>{schedule.name}</Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    Type: {schedule.type}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label="edit"
                    color="primary"
                    onClick={() => openEditModal(schedule)}
                    sx={{ marginRight: 1, borderRadius: '50%', backgroundColor: 'action.hover' }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="view"
                    color="primary"
                    onClick={() => handleView(schedule)}
                    sx={{ marginRight: 1, borderRadius: '50%', backgroundColor: 'action.hover' }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    color="error"
                    onClick={() => handleDelete(schedule)}
                    sx={{ borderRadius: '50%', backgroundColor: 'action.hover' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={handlePrevious}
          sx={{ marginRight: 1 }}
          disabled={page === 0}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={handleNext}
          disabled={startIndex + schedulesPerPage >= schedules.length}
        >
          Next
        </Button>
      </Box>

      {currentGym['current_gym'] ?
        <>
          {/* Edit Schedule Modal */}
          <EditScheduleModal
            token={token}
            gym_id={currentGym['current_gym'].id}
            open={isEditModalOpen}
            onClose={closeEditModal}
            schedule={editSchedule}
            mode={mode}
            fetchSchedules={fetchSchedules}
          />

          {/* View Schedule Modal */}
          <ViewScheduleModal
            schedule={selectedSchedule}
            onClose={() => setSelectedSchedule(null)}
          />
        </>
        : null
      }
    </Box>
  );
};

export default ScheduleBoard;
