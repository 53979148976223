import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card, CardContent, List, ListItem, ListItemText, TextField, CircularProgress, Alert, Grid, Typography, Divider } from '@mui/material';
import { API } from './api-service';
import { useCookies } from 'react-cookie';

const UserProfileDialog: React.FC<{ open: boolean, onClose: () => void, data: any }> = ({ open, onClose, data }) => {
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [Token,setToken] = useCookies(['token']);
    const [society, setSociety] = useCookies(['society'])

    const handlePasswordChange = async () => {
        setLoading(true);
        setError('');
        setSuccess('');
        try {
            if (newPassword !== confirmPassword) {
                setError('Passwords do not match.');
                setLoading(false);
                return;
            }

            // Make API call to change the password
            await API.changePassword(Token['token'], oldPassword, newPassword);
            setLoading(false);
            setSuccess('Password changed successfully!');
            setShowPasswordForm(false);
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            setLoading(false);
            setError('Failed to change password.');
        }
    };

    if (!open || !data) {
        return null;
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>User Profile</DialogTitle>
            <DialogContent>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Personal Information</Typography>
                        <Divider />
                        <Grid container spacing={2} sx={{marginTop:'20px'}}>
                            <Grid item xs={6}>
                                <Typography variant="body1">Username: {data.username}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">Email: {data.email}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">Phone: {data.phone}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">Society: {society['society']}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">Wing: {data.wing}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        {showPasswordForm ? (
                            <div>
                                <Typography variant="h6">Change Password</Typography>
                                <Divider />
                                <TextField
                                    label="Old Password"
                                    type="password"
                                    fullWidth
                                    margin="normal"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                                <TextField
                                    label="New Password"
                                    type="password"
                                    fullWidth
                                    margin="normal"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <TextField
                                    label="Confirm New Password"
                                    type="password"
                                    fullWidth
                                    margin="normal"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <Button onClick={handlePasswordChange} color="primary" disabled={loading}>
                                    {loading ? <CircularProgress size={24} /> : 'Save'}
                                </Button>
                                {error && <Alert severity="error">{error}</Alert>}
                                {success && <Alert severity="success">{success}</Alert>}
                            </div>
                        ) : (
                            <Button onClick={() => setShowPasswordForm(true)} color="primary">Change Password</Button>
                        )}
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserProfileDialog;
