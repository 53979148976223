import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box, Button } from '@mui/material';

interface EditTrainerDialogProps {
  open: boolean;
  onClose: () => void;
  trainerData: any;
}

const EditTrainerDialog: React.FC<EditTrainerDialogProps> = ({ open, onClose, trainerData }) => {
  if (!trainerData) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Trainer Details</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="h6" gutterBottom>
            Name
          </Typography>
          <Typography variant="body1" paragraph>
            {trainerData.Name}
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Expertise
          </Typography>
          <Typography variant="body1" paragraph>
            {trainerData.Expertise}
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Members
          </Typography>
          <Box component="ul" sx={{ padding: 0, margin: 0, listStyleType: 'none' }}>
            {trainerData.Members.map((member: string, index: number) => (
              <Box component="li" key={index} sx={{ marginBottom: 1 }}>
                <Typography variant="body1">
                  {index + 1}. {member}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTrainerDialog;
