import React, { useEffect, useState } from 'react';
import { Container, Typography, TextField, Button, Grid, Link, Box, MenuItem, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import '../css/AuthForm.css';
import { API } from './api-service';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function isValididentifier(input: any) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10}$/;

    return emailPattern.test(input) || phonePattern.test(input);
}

function AuthForm() {
    const [isLoginView, setIsLoginView] = useState(true);
    const [identifier, setIdentifier] = useCookies(['identifier'])
    const [society, setSociety] = useCookies(['society'])
    const [Token, setToken] = useCookies(['token']);
    const [Role, setRole] = useCookies(['role']);
    const [societies, setSocieties] = useState([]);
    const [wings, setWings] = useState([]);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const fetchSocieties = async () => {
            try {
                const resp = await API.getSocieties();
                console.log(resp);
                setSocieties(resp);
            } catch (error) {
                console.error('Error fetching societies:', error);
            }
        };

        fetchSocieties();
    }, []);

    useEffect(() => {
        const CheckAuth = async () => {
            if (Token['token']) (window.location.href = '/home')
        };

        CheckAuth();
    }, [Token]);

    const [formDataRegister, setFormDataRegister] = useState({
        username:'',
        phone: '',
        password: '',
        role: '',
        society: '',
        wing: '',
        address: '',
        flat_number: '',
        email: ''
    });

    const [formDataLogin, setFormDataLogin] = useState({
        role: '',
        identifier: '',
        password: '',
        identifierError: false,
        phone: ''
    });

    const handleChangeLogin = (event: any) => {
        const { name, value } = event.target;
        setFormDataLogin(prev => ({
            ...prev,
            [name]: value
        }));

        if (name === 'identifier') {
            const isValid = isValididentifier(value);
            setFormDataLogin((prev: any) => ({
                ...prev,
                [name]: value,
                identifierError: !isValid
            }));
        } else {
            setFormDataLogin((prev: any) => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleToggle = (event: any) => {
        event.preventDefault();
        setIsLoginView(!isLoginView);
    };

    const handleChangeRegister = async(event: any) => {
        const { name, value } = event.target;
        setFormDataRegister(prev => ({
            ...prev,
            [name]: value
        }));
        if(name=='society'){
            const wings_list: any = await API.fetchWingList(value);
            console.log(wings_list)
            setWings(wings_list);
        }
    };

    const handleSubmitRegister = (event: any) => {
        event.preventDefault();
        console.log(formDataRegister);
        API.RegisterApiCall(formDataRegister).then(
            resp => {
                if (resp.token) {
                    setToken('token', resp.token)
                    setRole('role',formDataLogin.role)
                }
                console.log(resp.message)
                // toast.success(resp.message);
                alert(resp.message);

            })
            .catch(error => console.log(error))
    };

    const handleSubmitLogin = (event: any) => {
        event.preventDefault();
        console.log(formDataLogin)
        formDataLogin.phone = formDataLogin.identifier
        API.LoginApiCall(formDataLogin).then(resp => {
            if (resp.token) {
                {
                    setSociety('society', resp.society)
                    setToken('token', resp.token)
                    setRole('role',formDataLogin.role)
                    window.location.href = '/home'
                }
            }
        })
            .catch(error => console.log(error))
        setIdentifier('identifier', formDataLogin.identifier)
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    return (
        <Container component="main" maxWidth="sm" className='auth-container'>
            <Box style={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography
                    component="h1"
                    variant="h3" className='auth-form-header'
                    color={"primary.main"}
                    style={{ marginBottom: 8, display: 'flex' }}
                >
                    {isLoginView ? 'Login' : 'Register'}
                </Typography>
                {isLoginView ?
                    <Box component="form" style={{ width: '100%', marginTop: 1 }} onSubmit={handleSubmitLogin} sx={{ mt: 1 }}>
                        <TextField
                            fullWidth
                            select
                            label="Role"
                            variant="outlined"
                            name="role"
                            value={formDataLogin.role}
                            onChange={handleChangeLogin}
                            sx={{
                                input: { color: 'primary.main' },
                                '& label': { color: 'blue' },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: 'blue' },
                                    '&:hover fieldset': { borderColor: 'lightblue' },
                                    '&.Mui-focused fieldset': { borderColor: 'blue' },
                                },
                                '& .MuiSelect-select': { color: 'primary.main', textAlign: 'left' },
                                '& .MuiPaper-root': { backgroundColor: '#333', color: 'primary.main' },
                                '& .MuiMenuItem-root': {
                                    '&.Mui-selected': { backgroundColor: '#000001' },
                                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                                }
                            }}
                            required
                        >
                            <MenuItem value="Gym">Gym</MenuItem>
                            <MenuItem value="Society">Society</MenuItem>
                        </TextField>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="identifier"
                            label="Email or Phone"
                            type="text"
                            autoComplete="email"
                            error={formDataLogin.identifierError}
                            helperText={formDataLogin.identifierError ? "Please enter a valid email or phone number." : ""}
                            sx={{
                                input: { color: 'primary.main' },
                                '& label': { color: 'blue' },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: 'blue' },
                                    '&:hover fieldset': { borderColor: 'lightblue' },
                                    '&.Mui-focused fieldset': { borderColor: 'blue' },
                                }
                            }}
                            onChange={handleChangeLogin}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            sx={{
                                input: { color: 'primary.main' },
                                '& label': { color: 'blue' },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: 'blue' },
                                    '&:hover fieldset': { borderColor: 'lightblue' },
                                    '&.Mui-focused fieldset': { borderColor: 'blue' },
                                }
                            }}
                            onChange={handleChangeLogin}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ margin: '24px 0px 16px' }}
                        >
                            {isLoginView ? 'Login' : 'Register'}
                        </Button>

                    </Box> :

                    <Box component="form" onSubmit={handleSubmitRegister} sx={{ mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Username"
                                    variant="outlined"
                                    type="text"
                                    name="username"
                                    value={formDataRegister.username}
                                    onChange={handleChangeRegister}
                                    sx={{
                                        input: { color: 'primary.main' },
                                        '& label': { color: 'blue' },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': { borderColor: 'blue' },
                                            '&:hover fieldset': { borderColor: 'lightblue' },
                                            '&.Mui-focused fieldset': { borderColor: 'blue' },
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Phone"
                                    variant="outlined"
                                    type="text"
                                    name="phone"
                                    value={formDataRegister.phone}
                                    onChange={handleChangeRegister}
                                    sx={{
                                        input: { color: 'primary.main' },
                                        '& label': { color: 'blue' },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': { borderColor: 'blue' },
                                            '&:hover fieldset': { borderColor: 'lightblue' },
                                            '&.Mui-focused fieldset': { borderColor: 'blue' },
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Role"
                                    variant="outlined"
                                    type="text"
                                    name="role"
                                    value={formDataRegister.role}
                                    onChange={handleChangeRegister}
                                    sx={{
                                        input: { color: 'primary.main' },
                                        '& label': { color: 'blue' },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': { borderColor: 'blue' },
                                            '&:hover fieldset': { borderColor: 'lightblue' },
                                            '&.Mui-focused fieldset': { borderColor: 'blue' },
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    select
                                    label="Society"
                                    variant="outlined"
                                    name="society"
                                    value={formDataRegister.society}
                                    onChange={handleChangeRegister}
                                    sx={{
                                        input: { color: 'primary.main' },
                                        '& label': { color: 'blue' },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': { borderColor: 'blue' },
                                            '&:hover fieldset': { borderColor: 'lightblue' },
                                            '&.Mui-focused fieldset': { borderColor: 'blue' },
                                        },
                                        '& .MuiSelect-select': { color: 'primary.main', textAlign: 'left' },
                                        '& .MuiPaper-root': { backgroundColor: '#333', color: 'primary.main' },
                                        '& .MuiMenuItem-root': {
                                            '&.Mui-selected': { backgroundColor: '#000001' },
                                            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                                        }
                                    }}
                                    required
                                >
                                    {societies.map((society: any) => (
                                        <MenuItem key={society.id} value={society.id}>
                                            {society.society_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    select
                                    label="Wing"
                                    variant="outlined"
                                    name="wing"
                                    value={formDataRegister.wing}
                                    onChange={handleChangeRegister}
                                    sx={{
                                        input: { color: 'primary.main' },
                                        '& label': { color: 'blue' },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': { borderColor: 'blue' },
                                            '&:hover fieldset': { borderColor: 'lightblue' },
                                            '&.Mui-focused fieldset': { borderColor: 'blue' },
                                        },
                                        '& .MuiSelect-select': { color: 'primary.main', textAlign: 'left' },
                                        '& .MuiPaper-root': { backgroundColor: '#333', color: 'primary.main' },
                                        '& .MuiMenuItem-root': {
                                            '&.Mui-selected': { backgroundColor: '#000001' },
                                            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                                        }
                                    }}
                                    required
                                >
                                    {wings.map((wing: any) => (
                                        <MenuItem key={wing.id} value={wing.id}>
                                            {wing.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Address"
                                    variant="outlined"
                                    type="text"
                                    name="address"
                                    value={formDataRegister.address}
                                    onChange={handleChangeRegister}
                                    sx={{
                                        input: { color: 'primary.main' },
                                        '& label': { color: 'blue' },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': { borderColor: 'blue' },
                                            '&:hover fieldset': { borderColor: 'lightblue' },
                                            '&.Mui-focused fieldset': { borderColor: 'blue' },
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Flat Number"
                                    variant="outlined"
                                    type="text"
                                    name="flat_number"
                                    value={formDataRegister.flat_number}
                                    onChange={handleChangeRegister}
                                    sx={{
                                        input: { color: 'primary.main' },
                                        '& label': { color: 'blue' },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': { borderColor: 'blue' },
                                            '&:hover fieldset': { borderColor: 'lightblue' },
                                            '&.Mui-focused fieldset': { borderColor: 'blue' },
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    type="email"
                                    name="email"
                                    value={formDataRegister.email}
                                    onChange={handleChangeRegister}
                                    sx={{
                                        input: { color: 'primary.main' },
                                        '& label': { color: 'blue' },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': { borderColor: 'blue' },
                                            '&:hover fieldset': { borderColor: 'lightblue' },
                                            '&.Mui-focused fieldset': { borderColor: 'blue' },
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Password"
                                    variant="outlined"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    value={formDataRegister.password}
                                    onChange={handleChangeRegister}
                                    sx={{
                                        input: { color: 'primary.main' },
                                        '& label': { color: 'blue' },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': { borderColor: 'blue' },
                                            '&:hover fieldset': { borderColor: 'lightblue' },
                                            '&.Mui-focused fieldset': { borderColor: 'blue' },
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ margin: '24px 0px 16px' }}
                        >
                            {isLoginView ? 'Login' : 'Register'}
                        </Button>
                    </Box>}
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link href="#" variant="body2" onClick={handleToggle}>
                            {isLoginView ? "Don't have an account? Register" : "Already have an account? Login"}
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default AuthForm;
