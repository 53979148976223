import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3f48cc',
        },
        secondary: {
            main: '#CCCCCC', 
        },
        error: {
            main: '#E53E3E', 
        },
        background: {
            default: '#f4f6f8',
            paper: '#ffffff',
        },
        text: {
            primary: '#29303B', 
            secondary: '#606770',
        },
    },
});

export default theme;
