import React, { useEffect } from 'react';
import NavBar from './navbar';
import { useCookies } from 'react-cookie';
import { API } from './api-service';

const Layout = ({ children }: any) => {
  const [Token, setToken, deleteToken] = useCookies(['token']);

  useEffect(() => {
    if (Token['token']) {
      API.checkAuthStatusAndRedirectToLogin(Token['token'])
        .then((resp: any) => {
          if (resp === "JWT token has expired") {
            deleteToken('token');
            window.location.href = '/';
          }
        })
        .catch((error: any) => {
          deleteToken('token');
          window.location.href = '/';
        });
    }
  }, [Token]);

  return (
    <>
      <NavBar />
      <div className="content">
        {children}
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
