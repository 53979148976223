import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@mui/material';
import DataTable from './DataTable_Server';
import { useCookies } from 'react-cookie';
import { API } from './api-service';


const MaintenanceFields: React.FC<{ open: boolean, onClose: () => void }> = ({ open, onClose }) => {
    
    const [data, setData] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [sortField, setSortField] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [Token, setToken] = useCookies(['token']);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<Record<string, any>>({});
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [featureType, setFeatureType] = useState<string>('');
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [currentRow, setCurrentRow] = useState<any>(null);

    useEffect(() => {
        const checkAuth = async () => {
            if (!Token['token']) window.location.href = '/';
        };
        checkAuth();
    }, [Token]);

    const fetchData = async (queryParams: any = {}) => {
        if (!Token['token']) return;

        setLoading(true);
        try {
            const result = await API.fetchMaintenanceFieldsData(Token['token'], {
                search: searchQuery,
                page: page + 1, 
                page_size: rowsPerPage,
                sort_by: sortField,
                order: sortOrder,
                ...filters,
                ...queryParams,
            });
            // const finalData = result.results.map((item: any) => ({
            //     ...item            }));
            setData(result.results);
            setTotalRows(result.total_results);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [Token, page, rowsPerPage, searchQuery, sortField, sortOrder, filters]);

    useEffect(() => {
        if (data.length > 0) {
            setHeaders([...Object.keys(data[0])]);
        }
    }, [data]);

    const handleQueryType = (type: string, value: any) => {
        const queryParams: any = {};
        switch (type) {
            case 'sort':
                setSortField(value.property);
                setSortOrder(value.order);
                queryParams.sort_by = value.property;
                queryParams.order = value.order;
                break;
            case 'search':
                setSearchQuery(value);
                queryParams.search = value;
                break;
            case 'page':
                setPage(value);
                queryParams.page = value + 1; // Adjusting for 0-based index
                break;
            case 'rowsPerPage':
                setRowsPerPage(value);
                setPage(0);
                queryParams.page = 1; // Adjusting for 0-based index
                queryParams.page_size = value;
                break;
            case 'booleanChange':
                handleBooleanChange(value.id, value.header, value.value);
                return;
            case 'edit':
                handleEdit(value);
                return;
            case 'delete':
                handleDelete(value);
                return;

            default:
                break;
        }
        fetchData(queryParams);
    };

    const handleBooleanChange = async (id: number, header: string, value: boolean) => {
        console.log("boolean change");
    };

    const handleEdit = (row: any) => {
        console.log(`Edit clicked for row with id: ${row}`);
        setCurrentRow(row);
        setEditDialogOpen(true);
    };

    const handleDelete = (id: number) => {
        console.log(`Delete clicked for row with id: ${id}`);
    };

    const handleFilterChange = (selectedFilters: Record<string, any>, filterName: string) => {
        setFilters(prevFilters => ({ ...prevFilters, ...selectedFilters }));
        fetchData({ ...filters, ...selectedFilters });
    };

    if (!Token['token']) {
        window.location.href = '/';
    }
    const handleMaintenanceFields = () =>{
        window.location.href = '/contact';
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" >
            <DialogTitle>Maintenance Fields</DialogTitle>
            <DialogContent>
                <Button variant="outlined" sx={{ marginBottom: '10px' }} onClick={handleMaintenanceFields}>Manage maintenance fields</Button>
                <DataTable
                        headers={headers}
                        data={data}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        rowsPerPage={rowsPerPage}
                        totalRows={totalRows}
                        queryType={handleQueryType}
                        featureName="maintenanceFields"
                    />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default MaintenanceFields;
