import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Divider } from '@mui/material';

interface ExerciseFormProps {
  exercises: string[];
  day: string;
  onAddExercise: (day: string, exercise: { [exercise: string]: number }) => void;
  onDeleteExercise: (day: string, exerciseName: string) => void; // Expects index
  exercisesForDay: { [exercise: string]: string } | string; // Can be an object or string
}

const ExerciseForm: React.FC<ExerciseFormProps> = ({ exercises, day, onAddExercise, onDeleteExercise, exercisesForDay }) => {
  const [exerciseName, setExerciseName] = useState<string>('');
  const [reps, setReps] = useState<string>('');

  const handleAdd = () => {
    console.log(exerciseName)
    if (exerciseName && reps) {
      const repsNumber = parseInt(reps, 10);
      if (!isNaN(repsNumber) && repsNumber >= 0) {
        onAddExercise(day, { [exerciseName]: repsNumber });
        setExerciseName('');
        setReps('');
      } else {
        alert('Please enter a valid number of reps.');
      }
    }
  };

  // Convert exercisesForDay to array if it's an object
  const exercisesArray = typeof exercisesForDay === 'object' ? Object.entries(exercisesForDay) : [];

  return (
    <Box sx={{ padding: 2 }} maxWidth="m">
      <Typography variant="h6" gutterBottom>
        Exercises for {day}
      </Typography>
      <Typography variant='caption'>For rest day add Rest with 0 Reps, remove Rest to add exercise </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ mb: 2 }}>
        {exercisesArray.length > 0 ? (
          exercisesArray.map(([name, reps], index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {name}: {reps}
              </Typography>
              <Button 
                variant="outlined" 
                color="error" 
                onClick={() => onDeleteExercise(day, name)}
                sx={{ ml: 2 }}
              >
                Delete
              </Button>
            </Box>
          ))
        ) : (
          <Typography>No exercises available.</Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Exercise Name"
          value={exerciseName}
          onChange={e => setExerciseName(e.target.value)}
          fullWidth
          variant="outlined"
          sx={{ mb: 1 }}
        />
        <TextField
          type="number"
          label="Reps"
          value={reps}
          onChange={e => setReps(e.target.value)}
          InputProps={{ inputProps: { max: 25, min: 1 } }}
          fullWidth
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Button
          onClick={handleAdd}
          variant="contained"
          color="primary"
        >
          Add Exercise
        </Button>
      </Box>
    </Box>
  );
};

export default ExerciseForm;
