import React, { useState, useEffect } from 'react';
import { Button, Box, Grid } from '@mui/material';
import DataTable from './DataTable_Server';
import { API } from './api-service';
import { useCookies } from 'react-cookie';
import GeneralizedFilter from './Filter';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabProvider from './TabProvider';

// interface Amenities {
//     user_id: 
//     name: string,
//     time :
// }

const VisitorsHistory: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [sortField, setSortField] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [Token, setToken] = useCookies(['token']);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<Record<string, any>>({});
    const [tabvalue, setTabValue] = React.useState(0);
    const [tabName, setTabName] = React.useState("pending");


    useEffect(() => {
        const checkAuth = async () => {
            if (!Token['token']) window.location.href = '/';
        };
        checkAuth();
    }, [Token]);

    const fetchData = async (queryParams: any = {}) => {
        if (!Token['token']) return;
        var visitor_status = {
            status: tabName
        }
        setLoading(true);
        try {
            const result = await API.fetchVisitorsData(Token['token'], {
                ...visitor_status,
                search: searchQuery,
                page: page + 1,
                page_size: rowsPerPage,
                sort_by: sortField,
                sort_order: sortOrder,
                ...filters,
                ...queryParams,
            });

            
            // if (result) {
            //     const visitors_data = result.results.map((item: any) => ({
            //         id: item.id,
            //         name: item.visitor_name,
            //         phone_number: item.phone_number,
            //         wing:item.wing,
            //         flat: item.flat,
            //         entry_time: item.start_time,
            //         exit_time: item.end_time,
            //         accepted_by_resident: item.accepted_by_resident,
            //         accepted_by_security_guard: item.accepted_by_security_guard
            //     }));
                setData(result.results);
                setTotalRows(result.total_results);
            // }
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [Token, page, rowsPerPage, searchQuery, sortField, sortOrder, filters,tabName]);

    useEffect(() => {
        if (data.length > 0) {
            setHeaders([...Object.keys(data[0])]);
        }
    }, [data]);

    const handleQueryType = (type: string, value: any) => {
        const queryParams: any = {};
        switch (type) {
            case 'sort':
                setSortField(value.property);
                setSortOrder(value.order);
                queryParams.sortField = value.property;
                queryParams.sortOrder = value.order;
                break;
            case 'search':
                setSearchQuery(value);
                queryParams.search = value;
                break;
            case 'page':
                setPage(value);
                queryParams.page = value;
                break;
            case 'rowsPerPage':
                setRowsPerPage(value);
                setPage(0);
                queryParams.page = 0;
                queryParams.pageSize = value;
                break;
            case 'booleanChange':
                handleBooleanChange(value.id, value.header, value.value);
                return;
            case 'edit':
                handleEdit(value);
                return;
            case 'delete':
                handleDelete(value);
                return;
            default:
                break;
        }
        fetchData(queryParams);
    };

    const handleBooleanChange = async (id: number, header: string, value: boolean) => {
        console.log("boolean change");
    };

    const handleEdit = (id: number) => {
        console.log(`Edit clicked for row with id: ${id}`);
    };

    const handleDelete = (id: number) => {
        console.log(`Delete clicked for row with id: ${id}`);
    };

    const handleFilterChange = (selectedFilters: Record<string, any>, filterName: string) => {
        setFilters(prevFilters => ({ ...prevFilters, ...selectedFilters }));
        fetchData({ ...filters, ...selectedFilters });
    };

    if (!Token['token']) {
        window.location.href = '/';
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        setTabName(a11yProps(newValue).tab)
        fetchData();
    };
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            tab: ['pending', 'pre-approved', 'history'][index],
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Box padding={5}>
            <h1>Visitor History</h1>
            {/* <Box sx={{ width: '100%', marginBottom: '30px', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ borderBottom: 4, borderColor: 'divider' }}>
                    <Tabs value={tabvalue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="pending" {...a11yProps(0)} />
                        <Tab label="pre-approved" {...a11yProps(1)} />
                        <Tab label="History" {...a11yProps(2)} />
                    </Tabs>
                </Box>
            </Box> */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <DataTable
                        headers={headers}
                        data={data}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        rowsPerPage={rowsPerPage}
                        totalRows={totalRows}
                        queryType={handleQueryType}
                        featureName="visitors"
                    />
                </Grid>
                {/* <Grid item xs={12} md={2}>
                    <GeneralizedFilter feature_type="wings_list" filterName="Wing" onFilterChange={handleFilterChange} token={Token['token']} />
                </Grid> */}
            </Grid>
        </Box>
    );
};

export default VisitorsHistory;

