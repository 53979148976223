import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Paper, Button, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { API } from './api-service';
import { useCookies } from 'react-cookie';
import AddMembershipModal from './AddMembershipModal'; // Ensure this import is correct

interface Membership {
    id: number;
    title: string;
    description: string;
    price: string;
    image: string | null;
    months: number;
    gym: number;
}

interface MembershipBoardProps {
    token: string;
}

const MembershipBoard: React.FC<MembershipBoardProps> = ({ token }) => {
    const [memberships, setMemberships] = useState<Membership[]>([]);
    const [page, setPage] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);
    const [editMembership, setEditMembership] = useState<Membership | null>(null);
    const [mode, setMode] = useState<'add' | 'edit'>('add');
    const [currentGym] = useCookies(['current_gym']);

    const membershipsPerPage = 2;

    useEffect(() => {
        const checkAuth = async () => {
            if (!token) window.location.href = '/';
        };
        checkAuth();
    }, [token]);

    const fetchMemberships = async () => {
        if (token && currentGym['current_gym']) {
            try {
                console.log("started memberships fetch")
                const memberships = await API.Memberships(token, currentGym['current_gym'].id, 'GET');
                setMemberships(memberships || []);
            } catch (error) {
                setMemberships([]);
                console.error("Failed to fetch memberships:", error);
            }
        }
    };

    useEffect(() => {
        fetchMemberships();
    }, [token, currentGym]);

    const handlePrevious = () => {
        setPage(prevPage => Math.max(prevPage - 1, 0));
    };

    const handleNext = () => {
        setPage(prevPage => Math.min(prevPage + 1, Math.floor(memberships.length / membershipsPerPage)));
    };

    const startIndex = page * membershipsPerPage;
    const displayedMemberships = memberships.slice(startIndex, startIndex + membershipsPerPage);
    console.log(displayedMemberships)
    const openEditModal = (membership: Membership) => {
        setEditMembership(membership);
        setMode('edit');
        setModalOpen(true);
    };

    const openAddModal = () => {
        setEditMembership(null);
        setMode('add');
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        fetchMemberships();
    };

    const handleDelete = async (membership: Membership) => {
        if (token && currentGym['current_gym']) {
            try {
                await API.Memberships(token, currentGym['current_gym'].id,'DELETE',"", membership.id);
                fetchMemberships();
            } catch (error) {
                console.error("Failed to delete membership:", error);
            }
        }
    };

    return (
        <Box>
            <Typography variant="h5" component="h2" gutterBottom color='primary.main'>
                Membership Board
                <IconButton aria-label="add" color="primary" onClick={openAddModal}>
                    <AddIcon />
                </IconButton>
            </Typography>
            <Divider />
            <Box sx={{ maxHeight: '500px', overflowY: 'auto', mt: 2 }}>
                <Grid container spacing={4}>
                    {displayedMemberships.map(membership => (
                        <Grid item xs={12} md={6} key={membership.id}>
                            <Paper elevation={3} sx={{ marginBottom: 2, padding: 6.5, border: '1px solid #ccc', ":hover": { boxShadow: 10 } }}>
                                <Typography variant="h6">{membership.title}</Typography>
                                <Typography variant="body2">{membership.description}</Typography>
                                <Typography variant="body1">Price: ${membership.price}</Typography>
                                <Typography variant="body2">Duration: {membership.months} months</Typography>
                                {/* Add image rendering if necessary */}
                                <Box display="flex" justifyContent="space-between">
                                    <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(membership)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton aria-label="edit" color="primary" onClick={() => openEditModal(membership)}>
                                        <EditIcon />
                                    </IconButton>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    onClick={handlePrevious}
                    sx={{ marginRight: 1 }}
                    disabled={page === 0}
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForwardIcon />}
                    onClick={handleNext}
                    disabled={startIndex + membershipsPerPage >= memberships.length}
                >
                    Next
                </Button>
            </Box>
            <AddMembershipModal
                open={isModalOpen}
                onClose={closeModal}
                initialMembership={editMembership}
                mode={mode}
            />
        </Box>
    );
};

export default MembershipBoard;
