import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@mui/material';
import { API } from './api-service';
import { useCookies } from 'react-cookie';

const EditPaymentStatusDialog: React.FC<{ open: boolean, onClose: () => void, row: any,onSave:() => void }> = ({ open, onClose, row,onSave }) => {
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [Token, setToken] = useCookies(['token']);

    useEffect(() => {
        if (row) {
            setPaymentStatus(row.Last_Bill_Payment_Status || '');
            setPaymentMethod(row.Payment_Method || '');
        }
    }, [row]);

    const handleSave = () => {
        var request_body = {
            last_bill_payment_status: paymentStatus,
            payment_method: paymentMethod,
            // payment_date: 
        };
        const response = API.updateMaintenanceBill(Token['token'], row.ID, request_body);
        console.log(response);
        onSave();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm'>
            <DialogTitle >Edit Payment Status</DialogTitle>
            <DialogContent>
                <TextField
                    select
                    label="Payment Status"
                    value={paymentStatus}
                    onChange={(e) => setPaymentStatus(e.target.value)}
                    fullWidth
                    sx={{marginTop: '10px'}}
                >
                    <MenuItem value="Paid">Paid</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Late Payment">Late Payment</MenuItem>
                </TextField>
                <TextField
                    label="Payment Method"
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditPaymentStatusDialog;
