import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
  FormGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { API } from './api-service';
import { useCookies } from 'react-cookie';

interface EditGymMemberDialogProps {
  open: boolean;
  onClose: () => void;
  token: string;
  user_id: string;
}

const EditGymMemberDialog: React.FC<EditGymMemberDialogProps> = ({ open, onClose, token, user_id }) => {
  const [currentGym] = useCookies(['current_gym']);
  const [userSchedules, setUserSchedules] = useState<any[]>([]);
  const [allSchedules, setAllSchedules] = useState<any[]>([]);
  const [selectedSchedules, setSelectedSchedules] = useState<Set<number>>(new Set());
  const [expanded, setExpanded] = useState<string | false>(false);
  const [expanded2, setExpanded2] = useState<string | false>(false);
  const [Trainer, SetTrainer] = useState<any>()
  const [TrainerList, SetTrainerList] = useState<any[]>([])

  useEffect(() => {
    if (open) {
      fetchUserSchedules();
      fetchAllSchedules();
      fetchTrainer();
      fetchAllTrainers();
    }
  }, [open]);

  const fetchUserSchedules = async () => {
    try {
      const schedules = await API.getGymSchedules(token, currentGym['current_gym'].id, '', user_id, '');
      setUserSchedules(schedules);
      setSelectedSchedules(new Set(schedules.map((schedule: any) => schedule.id)));
    } catch (error) {
      console.error('Error fetching user schedules:', error);
    }
  };

  const fetchTrainer = async () => {
    try {
      const result = await API.memberdata(token, user_id);

      if (result) {
        SetTrainer(result.trainer)
        console.log(Trainer)
      }
    }
    catch (error) {
      console.error('Error fetching trainer of user:', error);
    }
  }

  const fetchAllTrainers = async () => {
    const alltrainers = await API.manageTrainer(token, currentGym['current_gym'].id, null, null, "get")
    console.log(alltrainers)
    SetTrainerList(alltrainers)
  }

  const fetchAllSchedules = async () => {
    try {
      const schedules = await API.getGymSchedules(token, currentGym['current_gym'].id, '', '', '');
      setAllSchedules(schedules);
    } catch (error) {
      console.error('Error fetching all schedules:', error);
    }
  };

  const handleScheduleChange = (scheduleId: number) => {
    setSelectedSchedules(prev => {
      const updated = new Set(prev);
      if (updated.has(scheduleId)) {
        updated.delete(scheduleId);
      } else {
        updated.add(scheduleId);
      }
      return updated;
    });
  };

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAccordionChange2 = (panel: string) => (event: React.SyntheticEvent, isExpanded2: boolean) => {
    setExpanded2(isExpanded2 ? panel : false);
  };

  const handleSubmit = async () => {
    try {
      const selectedScheduleArray = Array.from(selectedSchedules);
      await API.GymMembers(
        token,
        currentGym['current_gym'].id,
        'PUT',
        { schedules: selectedScheduleArray },
        user_id
      );
      onClose();
    } catch (error) {
      console.error('Error updating user schedules:', error);
    }
  };

  const renderActivities = (activities: any, schedule: any) => {
    if (typeof activities === 'string') {
      return activities;
    }

    if (schedule?.type === 'diet') {
      // Handle diet items
      return (
        <List>
          {Object.entries(activities).map(([foodItem, quantity]: any) => (
            <ListItem key={foodItem}>
              <ListItemText
                primary={foodItem}
                secondary={`Quantity: ${quantity}`}
              />
            </ListItem>
          ))}
        </List>
      );
    }

    // Handle workout items
    return (
      <List>
        {Object.entries(activities).map(([exercise, reps]: any) => (
          <ListItem key={exercise}>
            <ListItemText
              primary={exercise}
              secondary={`Reps: ${reps}`}
            />
          </ListItem>
        ))}
      </List>
    );
  };

  const renderScheduleDetails = (schedule: any) => (
    <Box sx={{ flex: '0 1 auto' }}>
      <Typography id="view-modal-title" variant="h6" component="h2">
        {schedule?.name}
      </Typography>
      {/* <Typography id="view-modal-description" sx={{ mt: 2 }}>
            <strong>Name:</strong> {schedule?.name}
          </Typography> */}
      <Typography id="view-modal-description" sx={{ mt: 2 }}>
        <strong>Type:</strong> {schedule?.type}
      </Typography>
      <Typography id="view-modal-description" sx={{ mt: 2 }}>
        <strong>Plans:</strong>
        <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
          <List>
            {Object.entries(schedule?.plans || {}).map(([day, activities]: any) => (
              <ListItem key={day}>
                <ListItemText
                  primary={<strong>{day}</strong>}
                  secondary={renderActivities(activities, schedule)}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Typography>
    </Box>
  );

  const handleTrainerChange = (event: any) => {
    SetTrainer(event.target.value as any);
  };

  const handleSaveTrainer = async() =>{
    try {
      await API.GymMembers(
        token,
        currentGym['current_gym'].id,
        'PUT',
        { "trainer":Trainer.id },
        user_id
      );
      onClose();
    } catch (error) {
      console.error('Error updating user schedules:', error);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      {/* <Typography variant="h6">Select Trainer:</Typography>
        <FormControl sx={{ mb: 4 }}>
          <InputLabel id="trainer-select-label">Trainer</InputLabel>
          <Select
            labelId="trainer-select-label"
            value={Trainer?.id || ''}
            onChange={handleTrainerChange}
            label="Trainer"
          >
            {TrainerList.map((trainer:any) => (
              <MenuItem key={trainer.id} value={trainer.id}>
                {trainer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      {/* <Typography variant="h6">Assigned Schedules:</Typography> */}

      <DialogTitle>Gym Trainer</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Assigned Trainer:  {Trainer?.user?.username}</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Trainer</InputLabel>
          <Select
            value={Trainer}
            onChange={handleTrainerChange}
            label="Trainer"
          >
            {TrainerList.map(trainer => (
              <MenuItem key={trainer.id} value={trainer}>
                {trainer.user.username} - {trainer.expertise}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={handleSaveTrainer} variant="contained" color="primary">
          Save
        </Button>
      </DialogContent>

      <DialogTitle>Edit Gym Member Schedules:</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Assigned Schedules:</Typography>
        <FormGroup>
          {userSchedules.map(schedule => (
            <Accordion
              key={schedule.id}
              expanded={expanded === `panel-${schedule.id}`}
              onChange={handleAccordionChange(`panel-${schedule.id}`)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center" width="100%">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedSchedules.has(schedule.id)}
                        onChange={() => handleScheduleChange(schedule.id)}
                      />
                    }
                    label={schedule.name}
                    style={{ flex: 1 }}
                  />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {renderScheduleDetails(schedule)}
              </AccordionDetails>
            </Accordion>
          ))}
        </FormGroup>
        <Divider style={{ margin: '20px 0' }} />
        <Typography variant="h6">All Available Schedules:</Typography>
        <FormGroup>
          {allSchedules.map(schedule => (
            <Accordion
              key={schedule.id}
              expanded={expanded2 === `panel-${schedule.id}`}
              onChange={handleAccordionChange2(`panel-${schedule.id}`)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center" width="100%">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedSchedules.has(schedule.id)}
                        onChange={() => handleScheduleChange(schedule.id)}
                      />
                    }
                    label={schedule.name}
                    style={{ flex: 1 }}
                  />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {renderScheduleDetails(schedule)}
              </AccordionDetails>
            </Accordion>
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditGymMemberDialog;
