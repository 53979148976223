import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import { API } from './api-service';
import { useCookies } from 'react-cookie';

interface Membership {
    id: number;
    title: string;
    description: string;
    price: string;
    image: string | null;
    months: number;
    gym: number;
}

interface AddMembershipModalProps {
    open: boolean;
    onClose: () => void;
    initialMembership: Membership | null;
    mode: 'add' | 'edit';
}

const AddMembershipModal: React.FC<AddMembershipModalProps> = ({ open, onClose, initialMembership, mode }) => {
    const [membership, setMembership] = useState<Membership>({
        id: 0,
        title: '',
        description: '',
        price: '',
        image: null,
        months: 0,
        gym: 0
    });
    const [Token, setToken] = useCookies(['token']);
    const [currentGym, SetCurrentGym, deleteCurrentGym] = useCookies(['current_gym']);

    useEffect(() => {
        if (initialMembership) {
            setMembership(initialMembership);
        } else {
            setMembership({
                id: 0,
                title: '',
                description: '',
                price: '',
                image: null,
                months: 0,
                gym: 0
            });
        }
    }, [initialMembership]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setMembership(prev => ({ ...prev, [name]: value }));
    };

    const handleSave = () => {
        membership['gym'] = currentGym['current_gym'].id
        if(mode=="add"){
            const response =API.Memberships(Token['token'],currentGym['current_gym'].id,'POST',membership)
            console.log(response)
        }
        else{
            const response =API.Memberships(Token['token'],currentGym['current_gym'].id,'PUT',membership,initialMembership?.id)
            console.log(response)
        }
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box
                sx={{
                    p: 4,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 24,
                    width: '80%',
                    maxWidth: 600,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h6" mb={2}>
                    {mode === 'edit' ? 'Edit Membership' : 'Add Membership'}
                </Typography>
                <TextField
                    fullWidth
                    margin="normal"
                    name="title"
                    label="Title"
                    value={membership.title}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="description"
                    label="Description"
                    value={membership.description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="price"
                    label="Price"
                    value={membership.price}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="months"
                    label="Duration (months)"
                    type="number"
                    value={membership.months}
                    onChange={handleChange}
                />
                <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 2 }}>
                    Save
                </Button>
            </Box>
        </Modal>
    );
};

export default AddMembershipModal;
