import React from 'react';
import { Modal, Box, Typography, List, ListItem, ListItemText, Button } from '@mui/material';

interface ViewScheduleModalProps {
  schedule: any | null;
  onClose: () => void;
}

const ViewScheduleModal: React.FC<ViewScheduleModalProps> = ({ schedule, onClose }) => {
  const renderActivities = (activities: any) => {
    if (typeof activities === 'string') {
      return activities; 
    }

    if (schedule?.type === 'diet') {
      // Handle diet items
      return (
        <List>
          {Object.entries(activities).map(([foodItem, quantity]: any) => (
            <ListItem key={foodItem}>
              <ListItemText
                primary={foodItem}
                secondary={`Quantity: ${quantity}`}
              />
            </ListItem>
          ))}
        </List>
      );
    }

    // Handle workout items
    return (
      <List>
        {Object.entries(activities).map(([exercise, reps]: any) => (
          <ListItem key={exercise}>
            <ListItemText
              primary={exercise}
              secondary={`Reps: ${reps}`}
            />
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Modal
      open={Boolean(schedule)}
      onClose={onClose}
      aria-labelledby="view-modal-title"
      aria-describedby="view-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '80vh',
      }}>
        <Box sx={{ flex: '0 1 auto' }}>
          <Typography id="view-modal-title" variant="h6" component="h2">
          {schedule?.name}
          </Typography>
          {/* <Typography id="view-modal-description" sx={{ mt: 2 }}>
            <strong>Name:</strong> {schedule?.name}
          </Typography> */}
          <Typography id="view-modal-description" sx={{ mt: 2 }}>
            <strong>Type:</strong> {schedule?.type}
          </Typography>
          <Typography id="view-modal-description" sx={{ mt: 2 }}>
            <strong>Plans:</strong>
            <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
              <List>
                {Object.entries(schedule?.plans || {}).map(([day, activities]: any) => (
                  <ListItem key={day}>
                    <ListItemText
                      primary={<strong>{day}</strong>}
                      secondary={renderActivities(activities)}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Typography>
        </Box>
        <Box sx={{ flex: '0 0 auto', mt: 'auto' }}>
          <Button variant="contained" color="primary" onClick={onClose} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewScheduleModal;
