import React, { useState, useEffect, useCallback } from 'react';
import { Button, Box, Grid } from '@mui/material';
import DataTable from './DataTable_Server';
import { API } from './api-service';
import { useCookies } from 'react-cookie';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const AmenitiesDetails: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [sortField, setSortField] = useState<string>('booking_time');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [Token, setToken] = useCookies(['token']);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<Record<string, any>>({});
    const [tabValue, setTabValue] = useState<number>(0);
    const [tabList, setTabList] = useState<{id: number, name: string, is_paid: boolean}[]>([]);

    useEffect(() => {
        const checkAuth = async () => {
            if (!Token['token']) window.location.href = '/';
        };
        checkAuth();
    }, [Token]);

    useEffect(() => {
        const fetchAmenityList = async () => {
            try {
                const amenityList = await API.fetchAmenityList(Token['token']);
                setTabList(amenityList);
            } catch (error) {
                console.error('Error fetching amenity list:', error);
            }
        };
        fetchAmenityList();
    }, [Token]);

    const fetchData = useCallback(async (queryParams: any = {}) => {
        if (!Token['token'] || !tabList[tabValue]) return;
        const amenitiesType = { amenity_type: tabList[tabValue]?.name };
        setLoading(true);
        try {
            const result = await API.fetchAmenitieseData(Token['token'], {
                search: searchQuery,
                page: queryParams.page || page + 1,
                page_size: rowsPerPage,
                sort_by: sortField,
                sort_order: sortOrder,
                ...filters,
                ...queryParams,
                ...amenitiesType
            });
            setData(result.results);
            setTotalRows(result.total_results);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [Token, searchQuery, page, rowsPerPage, sortField, sortOrder, filters, tabValue, tabList]);

    useEffect(() => {
        if (tabList.length > 0) {
            fetchData();
        }
    }, [Token, page, rowsPerPage, searchQuery, sortField, sortOrder, filters, tabValue, fetchData, tabList]);

    useEffect(() => {
        if (data.length > 0) {
            setHeaders([...Object.keys(data[0])]);
        }
    }, [data]);

    const handleQueryType = (type: string, value: any) => {
        const queryParams: any = {};
        switch (type) {
            case 'sort':
                setSortField(value.property);
                setSortOrder(value.order);
                queryParams.sortField = value.property;
                queryParams.sortOrder = value.order;
                break;
            case 'search':
                setSearchQuery(value);
                queryParams.search = value;
                break;
            case 'page':
                setPage(value);
                queryParams.page = value;
                break;
            case 'rowsPerPage':
                setRowsPerPage(value);
                setPage(0);
                queryParams.page = 0;
                queryParams.pageSize = value;
                break;
            case 'booleanChange':
                handleBooleanChange(value.id, value.header, value.value);
                return;
            case 'edit':
                handleEdit(value);
                return;
            case 'delete':
                handleDelete(value);
                return;
            default:
                break;
        }
        fetchData(queryParams);
    };

    const handleBooleanChange = async (id: number, header: string, value: boolean) => {
        const currentTime = new Date();
        let endTime;

        if (value) {
            endTime = new Date(currentTime);
            endTime.setDate(endTime.getDate() + 5);
        } else {
            endTime = currentTime;
        }

        const request_body = {
            token: Token['token'],
            status: value,
            end_time: endTime.toISOString()
        };

        await API.updateAmenityBooking(id, request_body);
        await fetchData();
    };

    const handleEdit = (id: number) => {
        console.log(`Edit clicked for row with id: ${id}`);
    };

    const handleDelete = (id: number) => {
        console.log(`Delete clicked for row with id: ${id}`);
    };

    const handleFilterChange = (selectedFilters: Record<string, any>, filterName: string) => {
        setFilters(prevFilters => ({ ...prevFilters, ...selectedFilters }));
        fetchData({ ...filters, ...selectedFilters });
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Box padding={5}>
            <h1>Amenities</h1>
            <Box sx={{ width: '100%', marginBottom: '30px', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ borderBottom: 4, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        {tabList.map((tab, index) => (
                            <Tab key={index} label={tab.name} />
                        ))}
                    </Tabs>
                </Box>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <DataTable
                        headers={headers}
                        data={data}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        rowsPerPage={rowsPerPage}
                        totalRows={totalRows}
                        queryType={handleQueryType}
                        featureName="Amenities Booking"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AmenitiesDetails;
