import React, { useState, useEffect } from 'react';
import { Box, Typography, Checkbox, FormControlLabel, Button } from '@mui/material';
import { API } from './api-service';

interface GeneralizedFilterProps {
  feature_type: string;
  onFilterChange: (selectedFilters: Record<string, any>, filtername: string) => void;
  token: string;
  filterName: string;
}

const GeneralizedFilter: React.FC<GeneralizedFilterProps> = ({ feature_type, onFilterChange, token, filterName }) => {
  const [filterFields, setFilterFields] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const fetchFilterFields = async () => {
      try {
        const response = await API.fetchFilterFields(feature_type, token);
        const names = response.map((item: { name: string }) => item.name);
        setFilterFields(names);
      } catch (error) {
        console.error("Failed to fetch filter fields:", error);
      }
    };

    fetchFilterFields();
  }, [feature_type, token]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSelectedFilters(prevSelectedFilters => {
      const newSelectedFilters = {
        ...prevSelectedFilters,
        [name]: checked,
      };
      handleFilterClick(newSelectedFilters);
      return newSelectedFilters;
    });
  };

  const handleFilterClick = (filters: Record<string, boolean>) => {
    const checkedNames = Object.keys(filters).filter(key => filters[key]);
    const filterData = { [filterName.toLowerCase()]: checkedNames };
    onFilterChange(filterData, filterName);
  };

  return (
    <Box border={1} borderColor="grey.400" borderRadius={2} padding={2}>
      <Typography variant="h6">{filterName}</Typography>
      {filterFields.map((name: string) => (
        <FormControlLabel
          key={name}
          control={<Checkbox name={name} checked={!!selectedFilters[name]} onChange={handleCheckboxChange} />}
          label={name}
        />
      ))}
    </Box>
  );
};

export default GeneralizedFilter;
