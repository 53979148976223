import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
  Paper, IconButton, TextField, InputAdornment, TableSortLabel, Switch, Box, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility'; // Import the view icon

interface DataTableProps {
  headers: string[];
  data: Record<string, any>[];
  onEdit: (id: any) => void;
  onDelete: (id: any) => void;
  // onView: (id: any) => void; // Changed from onview to onView
  rowsPerPage: number;
  totalRows: number;
  queryType: (type: string, value: any) => void;
  featureName: string;
}

const DataTable: React.FC<DataTableProps> = ({
  headers,
  data,
  onEdit,
  onDelete,
  // onView, // Changed from onview to onView
  rowsPerPage,
  totalRows,
  queryType,
  featureName
}) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [month, setMonth] = useState<string>((new Date().getMonth()).toString());
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(20), (val, index) => currentYear - index); // Last 20 years

  const hiddenColumns: any[] = ["UserId", "ID"];
  const passUserIdOnedit: any[] = ["manage guards", "manage users"];
  const passIDOnedit: any[] = ["Amenities Booking", "maintenance", "manage members","manage trainer"];
  const yearMonthsFilterNeeded = ['maintenance'];
  const sendCompleteRow = ['helps-complaint', 'maintenance'];
  const showViewButtonList = ['manage trainer']

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    queryType('sort', { property, order: newOrder });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    queryType('search', event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    queryType('page', newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    queryType('rowsPerPage', parseInt(event.target.value, 10));
  };

  const handleBooleanChange = (event: React.ChangeEvent<HTMLInputElement>, row: Record<string, any>, header: string) => {
    var id = undefined;
    if (passUserIdOnedit.includes(featureName) && row.UserId) {
      id = row.UserId;
    }
    else if (passIDOnedit.includes(featureName)) {
      if (row.id) {
        id = row.id;
      }
      else if (row.ID) {
        id = row.ID;
      }
      else {
        id = row.Id;
      }
    }
    else if (sendCompleteRow.includes(featureName)) {
      id = row;
    }

    queryType('booleanChange', { id, header, value: event.target.checked });
  };

  const handleEditClick = (row: Record<string, any>) => {
    console.log(row);
    let idToSend = row.id;
    if (passUserIdOnedit.includes(featureName) && row.UserId) {
      idToSend = row.UserId;
    } else if (passIDOnedit.includes(featureName)) {
      if (row.id) {
        idToSend = row.id;
      }
      else if (row.ID) {
        idToSend = row.ID;
      }
      else {
        idToSend = row.Id;
      }
    }
    if (sendCompleteRow.includes(featureName)) {
      onEdit(row);
    } else {
      onEdit(idToSend);
    }
  };

  const handleDeleteClick = (row: Record<string, any>) => {
    let idToSend = row.id;
    if (featureName === 'manage guards' && row.UserId) {
      idToSend = row.UserId;
    } else if (passIDOnedit.includes(featureName)) {
      if (row.id) {
        idToSend = row.id;
      }
      else if (row.ID) {
        idToSend = row.ID;
      }
      else {
        idToSend = row.Id;
      }
    }
    if (sendCompleteRow.includes(featureName)) {
      idToSend = row
    }
    onDelete(idToSend);
  };

  const handleViewClick = (row: Record<string, any>) => {
    let idToSend = row.id;
    if (passUserIdOnedit.includes(featureName) && row.UserId) {
      idToSend = row.UserId;
    } else if (passIDOnedit.includes(featureName)) {
      if (row.id) {
        idToSend = row.id;
      }
      else if (row.ID) {
        idToSend = row.ID;
      }
      else {
        idToSend = row.Id;
      }
    }
    if (sendCompleteRow.includes(featureName)) {
      onEdit(row);
    } else {
      onEdit(idToSend);
    }
  };

  const formatString = (str: string): string => {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  useEffect(() => {
    console.log('Month:', month);
    console.log('Year:', year);
    queryType('month', month); // Pass the month directly
    queryType('year', year);
  }, [month, year]);

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding="16px">
        <TextField
          sx={{ marginBottom: '10px' }}
          variant="outlined"
          label="Search"
          value={search}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {yearMonthsFilterNeeded.includes(featureName) ?
          <Box display="flex" alignItems="center" marginLeft="auto">
            <FormControl variant="outlined" margin="normal" style={{ minWidth: 120 }}>
              <InputLabel id="month-label">Month</InputLabel>
              <Select
                labelId="month-label"
                value={month}
                onChange={(e) => setMonth(e.target.value)} // Set month directly
                label="Month"
              >
                {months.map((month, index) => (
                  <MenuItem key={index} value={index}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" margin="normal" style={{ minWidth: 120, marginLeft: '16px' }}>
              <InputLabel id="year-label">Year</InputLabel>
              <Select
                labelId="year-label"
                value={year}
                onChange={(e) => setYear(Number(e.target.value))}
                label="Year"
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          : <></>
        }
      </Box>

      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {headers.map((header) =>
                hiddenColumns.includes(header) ? null : (
                  <TableCell key={header}>
                    <TableSortLabel
                      active={orderBy === header}
                      direction={orderBy === header ? order : 'asc'}
                      onClick={() => handleRequestSort(header)}
                    >
                      {formatString(header)}
                    </TableSortLabel>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                {headers.map((header) =>
                  hiddenColumns.includes(header) ? null : (
                    <TableCell key={header}>
                      {header === 'Actions' ? (
                        <>
                          {row[header].includes('view') && showViewButtonList.includes(featureName) && (
                            <IconButton aria-label="view" color="primary" onClick={() => handleViewClick(row)}>
                              <VisibilityIcon />
                            </IconButton>
                          )}
                          {row[header].includes('edit') && (
                            <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                              <EditIcon />
                            </IconButton>
                          )}
                          {row[header].includes('delete') && (
                            <IconButton aria-label="delete" color="secondary" onClick={() => handleDeleteClick(row)}>
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </>
                      ) : 
                      typeof row[header] === 'boolean' ? (
                        <Switch
                          checked={row[header]}
                          color="primary"
                          onChange={(event) => handleBooleanChange(event, row, header)}
                        />
                      ) : 
                      typeof row[header] === 'string' ? (
                        formatString(row[header])
                      ) :  
                      (
                        row[header]
                      )}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DataTable;
